import { useEffect, useRef, useState, FC } from 'react';
import {useLocation, useParams} from 'react-router-dom';
import { StickyShareButtons } from 'sharethis-reactjs';

import { fetchPublicQuiltWithMemorial } from 'api/http-client/queries/quiltQueries';
import { httpMessages } from 'utilities/constants';
import { useTranslates } from 'providers/translates';
import { setEditableQuiltState } from 'store/reducers/quiltSlice';
import { setOpen } from 'store/reducers/notifySlice';
import { useAppDispatch, useAppSelector } from 'hooks';
import { DialogBlocks } from 'components/CreateMemorialQuiltComponents/DialogBlocks';
import { BlockModel } from 'components/CreateMemorialQuiltComponents/CreateMemorialQuiltStepThree/model';
import { Loader } from 'components/Loader/Loader';
import { AccessPermissionsTextEnum } from 'components/CreateMemorialQuiltComponents/CreateMemorialQuiltStepTwo/const';
import { openLinkInNewWindow } from "../../utilities";

const QuiltView: FC = () => {
  const { id } = useParams();
  const { translates } = useTranslates();
  const location = useLocation()
  const dispatch = useAppDispatch();
  const editableQuilt = useAppSelector((state) => state.quilt.editableQuilt);

  const [loading, setLoading] = useState(false);
  const [fetchError, setFetchError] = useState<string | null>(null);
  const [blocks, setBlocks] = useState<BlockModel[]>([]);
  const [editButtonStatus, setEditButtonStatus] = useState<AccessPermissionsTextEnum>(
    AccessPermissionsTextEnum.NO_EDIT
  );
  const stickyShareButtonsContainerRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (!id) return;
    setLoading(true);

    fetchPublicQuiltWithMemorial(id)
      .then((response) => {
        if (response.data.success) {
          setLoading(false);
          response.data.returned?.isOwner
            ? setEditButtonStatus(AccessPermissionsTextEnum.EDIT_IMAGES_MEMORIAL)
            : response.data.returned?.isContributor
              ? response.data.returned?.accessMode ===
                AccessPermissionsTextEnum.EDIT_IMAGES_MEMORIAL
                ? setEditButtonStatus(AccessPermissionsTextEnum.EDIT_IMAGES_MEMORIAL)
                : setEditButtonStatus(AccessPermissionsTextEnum.EDIT_IMAGES)
              : setEditButtonStatus(AccessPermissionsTextEnum.NO_EDIT);
          dispatch(setEditableQuiltState(response.data.returned));
          if (response.data.returned?.memorial?.data) {
            const blockData = JSON.parse(response.data.returned?.memorial?.data);
            if (Array.isArray(blockData)) {
              setBlocks(blockData);
            }
          }
        } else {
          dispatch(setOpen(`No quilt found`));
        }
      })
      .catch(() =>
        setFetchError(
          translates?.httpMessages?.fetchPublicQuiltWithMemorialError ||
            httpMessages.fetchPublicQuiltWithMemorialError
        )
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id, translates?.httpMessages?.fetchPublicQuiltWithMemorialError]);

  useEffect(() => {
    return () => {
      dispatch(setEditableQuiltState({}));
    };
  }, [dispatch]);

  // logic for adding custom instagram (change mailru)
  useEffect(() => {
    if (!stickyShareButtonsContainerRef.current) return;

    const stickyShareButtonRef = stickyShareButtonsContainerRef.current?.firstElementChild || null

    setTimeout(() => {
      const mailruShareButtonOrig = stickyShareButtonRef?.childNodes.item(1) as HTMLImageElement

      const mailruShareButton = mailruShareButtonOrig?.cloneNode(true) as HTMLImageElement
      const mailruShareButtonImg = mailruShareButton?.firstElementChild as HTMLImageElement

      if (!mailruShareButton || !mailruShareButtonImg) return;

      // change style
      mailruShareButton.style.backgroundColor = "#bc2a8d"
      mailruShareButtonImg.src = "https://platform-cdn.sharethis.com/img/instagram.svg"

      // add handler
      // const site = "https://cvh-quilt.venuiti.com/" + location.pathname
      // const urlToInstagram = `https://www.instagram.com?url=${site}`
      const urlToInstagram = 'https://www.instagram.com/accounts/login/?hl=en'
      mailruShareButton.addEventListener("click", () => openLinkInNewWindow(urlToInstagram))

      // replace old to new icon
      stickyShareButtonRef?.replaceChild(mailruShareButton, mailruShareButtonOrig)
    }, 500)
  }, [stickyShareButtonsContainerRef.current])

  if (fetchError) return <div style={{ textAlign: 'center', marginTop: '50px' }}>{fetchError}</div>;

  return (
    <>
      <DialogBlocks
        isTributes={editableQuilt?.isEnableTributesAndCondolence}
        isViewPage={true}
        blocksProps={blocks}
        editButtonStatus={editButtonStatus}
      />

      <div ref={stickyShareButtonsContainerRef}>
        {/*mailru - instagram */}
        <StickyShareButtons
          config={{
            alignment: 'right',
            color: 'social',
            enabled: true,
            font_size: 16,
            labels: 'cta',
            language: 'en',
            min_count: 0,
            networks: ['facebook', 'mailru', 'linkedin', 'twitter', 'email'],
            padding: 12,
            radius: 4,
            show_total: false,
            show_mobile: true,
            show_toggle: true,
            size: 48,
            top: 320,
          }}
        />
      </div>

      {/*<InlineFollowButtons*/}
      {/*  config={{*/}
      {/*    networks: ['instagram'],*/}
      {/*    alignment: 'right',*/}
      {/*    color: 'social',*/}
      {/*    enabled: true,*/}
      {/*    padding: 12,*/}
      {/*    radius: 4,*/}
      {/*    size: 48,*/}
      {/*    action: 'test',*/}
      {/*    action_enabled: true,*/}
      {/*    action_pos: '',*/}
      {/*    // @ts-ignore*/}
      {/*    profiles: {*/}
      {/*      instagram: '?url=https://www.drdrop.co/"'*/}
      {/*    }*/}
      {/*  }}*/}
      {/*></InlineFollowButtons>*/}
      <Loader loading={loading} />
    </>
  );
};

export default QuiltView;
