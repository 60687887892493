import React, {FC, MutableRefObject, useEffect, useState} from 'react';

import { CarouselItem } from '../CarouselItemType/CarouselItem';
import { QuiltsResponseMega } from '../../../../api/http-client/types';
import { CarouselItemType } from "../CarouselItemType/CarouselItemType"
import { CarouselItemType1 } from "../CarouselItemType/CarouselItemType1"
import {DataSettingModel} from "../../../../hooks/useCarouselHome";
import {useMediaQuery} from "@mui/material";

interface RowDeskPropsModel {
    indexOfRenderRow: number,
    items: QuiltsResponseMega[]
    settingData: DataSettingModel
}

export const RowDesk: FC<RowDeskPropsModel> = ({
    indexOfRenderRow,
    items,
    settingData
  }) => {
    const mediaMax1660 = useMediaQuery('(max-width:1660px)');

    const avgIndex = Math.floor(items.length / 2)
    const indexForStubs = mediaMax1660 ? [avgIndex - 1, avgIndex] : [avgIndex - 1, avgIndex, avgIndex + 1]
    const ignoreIndexRowForStubs = [0, settingData.rowLimit - 1]

    return (
        <>
            {
                indexOfRenderRow % 2 === 0 ? (
                    <CarouselItem>
                        {
                            items.map((item, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        <CarouselItemType isStub></CarouselItemType>
                                        { !ignoreIndexRowForStubs.includes(indexOfRenderRow) && indexForStubs.includes(index) ? (
                                          <CarouselItemType isStub></CarouselItemType>
                                        ) : (
                                          index % 2 === 0 ? (
                                            <CarouselItemType item={item}></CarouselItemType>
                                          ) : (
                                            <CarouselItemType1 item={item}></CarouselItemType1>
                                          )
                                        )}
                                    </React.Fragment>
                                )
                            })
                        }
                    </CarouselItem>
                ) : (
                    <CarouselItem>
                        {
                            items.map((item, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        { !ignoreIndexRowForStubs.includes(indexOfRenderRow) && indexForStubs.includes(index) ? (
                                          <CarouselItemType isStub></CarouselItemType>
                                        ) : (
                                          index % 2 !== 0 ? (
                                            <CarouselItemType item={item}></CarouselItemType>
                                          ) : (
                                            <CarouselItemType1 item={item}></CarouselItemType1>
                                          )
                                        )}
                                        <CarouselItemType isStub></CarouselItemType>
                                    </React.Fragment>
                                )
                            })
                        }
                    </CarouselItem>
                )
            }
        </>
    )
}
