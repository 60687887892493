import React, { useEffect, useState } from 'react';
import {useLocation, useParams, useSearchParams} from 'react-router-dom';
import {
  Box,
  Button,
  Card,
  Dialog,
  Grid,
  IconButton,
  Stack,
  TextField,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import HelpIcon from '@mui/icons-material/Help';

import { colors, emailRegex, passwordRegex } from 'utilities';
import { useAuth } from 'providers/auth-provider';
import { useTranslates } from 'providers/translates';
import {useAppDispatch, useRedirectAndScroll} from 'hooks';
import { setOpen } from 'store/reducers/notifySlice';
import { Loader } from 'components/Loader/Loader';
import { DarkTooltip } from 'components/Tooltips/CustomTooltips';
import { ForgetPasswordForm } from './ForgetPasswordForm';

import styles from './login.module.scss';

interface LocationFrom {
  from: {
    pathname: string;
  };
}

const Login: React.FC = () => {
  const { state } = useLocation();
  const { onlyRedirect } = useRedirectAndScroll({})
  const { login, isAuthorized, user } = useAuth();
  const { translates } = useTranslates();
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams()

  const fromPage = (state as LocationFrom)?.from?.pathname.includes("update") ? (state as LocationFrom)?.from?.pathname + '?step=3' : '/';

  const [showPass, setShowPass] = useState(false);
  const [dialog, setDialog] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const emailConfirmed = searchParams.get("email-confirmed")
    const reason = searchParams.get("reason")

    if (emailConfirmed && +emailConfirmed === 0) {
      dispatch(setOpen(`${reason || 'Error with confirming email'}`));
    }
  }, []);

  useEffect(() => {
    if (isAuthorized && !user?.isInvitedUser) {
      onlyRedirect('/', true);
    }
  }, [isAuthorized]);

  const { control, handleSubmit } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const onSubmit = (data: { email: string; password: string }) => {
    setLoading(true)

    login(data).then(() => {
      onlyRedirect(fromPage, true);
    }).catch((e) => {
      if ("errors" in e) {
        const errors: string[] = e.errors

        if (!errors.length) return;

        dispatch(setOpen(errors.join(", ")));
      }
    }).finally(() => {
      setLoading(false)
    });
  };

  return (
    <div className={styles.login_container}>
      <div className={`center ${styles.login_box}`}>
        <Card sx={{
          px: { xs: 2, sm: 3, md: 4, lg: 6 },
          py: { xs: 4, sm: 6 },
        }}>
          <span className="defaultTitle">{translates?.login?.loginForm || 'Login form'}</span>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container rowSpacing={1} columnSpacing={4} sx={{ mt: 4 }}>
              <Grid item xs={12} md={6} lg={5} xl={4}>
                <Controller
                  name="email"
                  control={control}
                  rules={{
                    required: { value: true, message: 'Email is required' },
                    pattern: emailRegex,
                  }}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <div className={`inputWithTitle ${styles.input_withTitle}`}>
                      <span>{translates?.login?.email || 'Email'}</span>
                      <TextField
                        className="customInput"
                        error={!!error}
                        fullWidth
                        value={value}
                        onChange={(e) => onChange(e.target.value.trim())}
                        helperText={
                          !!error ? `${translates?.login?.emailHelperTextError || error.message || 'Invalid email address'}` : ''
                        }
                        FormHelperTextProps={{ style: { fontSize: 16 } }}
                      />
                    </div>
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={5} xl={4}>
                <Controller
                  name="password"
                  control={control}
                  rules={{
                    required: { value: true, message: 'Password is required' },
                    pattern: passwordRegex,
                  }}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <div className={`inputWithTitle ${styles.input_withTitle}`}>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 1,
                        }}
                        >
                        <span>{translates?.login?.password || 'Password'}</span>
                        <DarkTooltip
                          title={
                            translates?.login?.passwordTooltipRequirements ||
                            'Password needs to be at least 6 characters and needs to include one lower case letter, one upper case letter, one number and one special character.'
                          }
                        >
                          <HelpIcon sx={{ color: colors.blue }} />
                        </DarkTooltip>
                      </Box>
                      <TextField
                        className="customInput"
                        error={!!error}
                        fullWidth
                        value={value}
                        onChange={(e) => onChange(e.target.value.trim())}
                        helperText={
                          !!error
                            ? `${translates?.login?.passwordHelperTextError || error.message || 'Incorrect password'}`
                            : ''
                        }
                        FormHelperTextProps={{ style: { fontSize: 16 } }}
                        type={showPass ? 'text' : 'password'}
                        InputProps={{
                          endAdornment: (
                            <DarkTooltip
                              title={
                                showPass
                                  ? `${translates?.login?.passwordTooltipTitleHide || 'Hide password'}`
                                  : `${translates?.login?.passwordTooltipTitleShow || 'Show password'}`
                              }
                            >
                              <IconButton onClick={() => setShowPass(!showPass)}>
                                {showPass ? <VisibilityOffIcon /> : <VisibilityIcon color="inherit" />}
                              </IconButton>
                            </DarkTooltip>
                          ),
                        }}
                      />
                    </div>
                  )}
                />
              </Grid>

              <Grid
                item
                xs={12}
                md={12}
                container
                justifyContent="space-between"
                alignItems="center"
                gap={4}
              >
                <Button variant="text" onClick={() => setDialog(true)} sx={{ mt: 1, p: 0, color: '#26a3c5', fontFamily: 'Open Sans, sans-serif', fontSize: 18 }}>
                  {translates?.login?.forgetPasswordBtnLabel || 'Forgot your password?'}
                </Button>

                <Stack direction={{ xs: 'column', sm: "row"}} spacing={3} sx={{ width: { xs: '100%', sm: 'auto' } }}>
                  <button
                    className="customButton"
                    type="submit"
                  >
                    {translates?.login?.login || 'Login'}
                  </button>
                  <button
                    className="customButton"
                    type="button"
                    onClick={() => onlyRedirect('/signup')}
                  >
                    {translates?.login?.createAccountBtnLabel || 'Create account'}
                  </button>
                </Stack>
              </Grid>
            </Grid>
          </form>
        </Card>
        <Dialog maxWidth="sm" fullWidth open={dialog} onClose={() => setDialog(!dialog)}>
          <ForgetPasswordForm translates={translates} setDialog={() => setDialog(false)} />
        </Dialog>
      </div>

      <Loader loading={loading} />
    </div>
  );
};

export default Login;
