import React from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { Box } from '@mui/material';

import { AddUsers, InvitedUsers } from '../components';
import { useAppSelector, useRedirectAndScroll } from '../../../hooks';

import styles from './styles.module.scss';

interface CreateMemorialQuiltStepTwoProps {}

export const CreateMemorialQuiltStepTwo: React.FC<CreateMemorialQuiltStepTwoProps> = () => {
  const { id } = useParams();
  const location = useLocation();
  const editableQuilt = useAppSelector((state) => state.quilt.editableQuilt);
  const { redirectAndScroll } = useRedirectAndScroll({});

  const backAction = () => {
    redirectAndScroll(`${location.pathname}?step=1`);
  };

  const continueAction = () => {
    redirectAndScroll(`${location.pathname}?step=3`);
  };

  return (
    <div className="center">
      <div className={styles.title}>
        <span>
          {editableQuilt?.firstName || ''} {editableQuilt?.lastName || ''}
        </span>
        <span> Memorial</span>
      </div>

      <div className={styles.inviteOther}>
        <h2 className="defaultTitle">Invite other to create the memorial with you</h2>

        <p className="defaultText">
          Use the fields below to invite others to edit the memorial or add images to the photo
          gallery. You can change any invited user's permissions on this pageor your{' '}
          <a href="/profile" className={styles.userSettings}>
            user settings
          </a>{' '}
          page.
        </p>
      </div>

      {id ? (
        <Box mt={6}>
          <AddUsers pageName={`${editableQuilt?.firstName || ''} ${editableQuilt?.lastName || ''}`} id={id}></AddUsers>
        </Box>
      ) : <></>}

      <div className={styles.invitedUsers}>
        <span className="defaultText">
          You can change the permissions of an invited user after they have accepted your invitation
          and created/logged in their account to help you create the memorial.
        </span>

        <Box mt={6}>{id && <InvitedUsers idQuilt={id}></InvitedUsers>}</Box>
      </div>

      <div className="delimitierGrey"></div>

      <div className={styles.actions}>
        <button className="customButton" onClick={backAction}>
          BACK
        </button>
        <button className="customButton" onClick={continueAction}>
          CONTINUE
        </button>
      </div>
    </div>
  );
};
