import { FC, useEffect, useState } from 'react';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { useLocation, useParams } from 'react-router-dom';

import { showDialog } from 'store/reducers/dialogsSlice';
import {
  errorBuilderPrivateDialog,
  errorBuilderPublishDialog,
  successBuilderDialog,
  notifyMessages,
} from 'utilities';
import { useRedirectAndScroll, useAppDispatch, useAppSelector, useQuilt } from 'hooks/index';
import { Loader } from 'components/Loader/Loader';
import { DefaultDialog } from 'components/DefaultDialog';
import {setIsSave, setOpen} from 'store/reducers/notifySlice';

import styles from './styles.module.scss';

interface CreateMemorialQuiltStepFourProps {}

export const CreateMemorialQuiltStepFour: FC<CreateMemorialQuiltStepFourProps> = () => {
  const { id } = useParams();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const editableQuiltStore = useAppSelector((state) => state.quilt.editableQuilt);
  const { redirectAndScroll } = useRedirectAndScroll({});
  const { publish, loading } = useQuilt({ id: id || '' });

  const [typeMemorialPublish, setTypeMemorialPublish] = useState('public');
  const [errorPublishDialog, setErrorPublishDialog] = useState(false);
  const [errorPrivateDialog, setErrorPrivateDialog] = useState(false);
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    // need for correct moving from first step or third
    dispatch(setIsSave(true))
  }, [])

  useEffect(() => {
    if (typeof editableQuiltStore?.isPublic !== "boolean") return;

    setTypeMemorialPublish(editableQuiltStore?.isPublic ? 'public' : 'private')
  }, [editableQuiltStore])

  useEffect(() => {
    if (!isMounted || !id) {
      setIsMounted(true);
      return;
    }

    if (typeMemorialPublish === 'public') {
      // setErrorPublishDialog(true);
    } else {
      setErrorPrivateDialog(true);
    }
  }, [typeMemorialPublish]);

  const showDialogBlocks = () => {
    dispatch(showDialog('blocks'));
  };

  const backAction = () => {
    redirectAndScroll(`${location.pathname}?step=3`);
  };

  const goToMyMemorial = () => {
    id && redirectAndScroll(`/quilts/${id}/memorial`);
  };

  const goToProfileUser = () => {
    redirectAndScroll(`/profile`);
  };

  const onPublish = () => {
    if (
      !editableQuiltStore?.finalImage &&
      !editableQuiltStore?.isPublic &&
      typeMemorialPublish === 'public'
    ) {
      dispatch(setOpen(notifyMessages.quiltPublicImageError));
      return;
    }
    publish.onPublish(typeMemorialPublish === 'public');
  };

  const handleViewing = ()=>{
    if (!!editableQuiltStore?.finalImage && editableQuiltStore.isPublic && editableQuiltStore.isPublished ){
      redirectAndScroll(`/?visible=${id}`)
    }
    else {
      dispatch(setOpen(notifyMessages.quiltViewHomePageError));
    }
  }

  return (
    <div className={`center ${styles.block}`} id="fourStep">
      <div className={styles.settings}>
        <div className={styles.title}>
          <span className="defaultTitle">Display Settings</span>
          <span className="defaultText">
            Choose how you want to share your memorial with others. You can change these setting
            later in your{' '}
            <span onClick={goToProfileUser} className={styles.settings__user}>
              user settings.
            </span>
          </span>
        </div>

        <div>
          <RadioGroup
            defaultValue="public"
            onChange={(e) => setTypeMemorialPublish(e.target.value)}
            value={typeMemorialPublish}
          >
            <FormControlLabel
              sx={{ alignItems: 'start' }}
              value="public"
              control={<Radio />}
              componentsProps={{
                typography: {
                  color: '#333333',
                  fontSize: { xs: '18px', sm: '22px' },
                  fontFamily: 'Open Sans;',
                },
              }}
              label={
                <p>
                  <b>Display your memorial publicly. </b>
                  <span>
                    Your memorial will be visible in the large quilt and searchable by name. You
                    will receive a link by email that you can share with friends and family.
                  </span>
                </p>
              }
            />

            <FormControlLabel
              sx={{ alignItems: 'start' }}
              value="private"
              control={<Radio />}
              componentsProps={{
                typography: {
                  color: '#333333',
                  fontSize: { xs: '18px', sm: '22px' },
                  fontFamily: 'Open Sans;',
                },
              }}
              label={
                <p>
                  <b>Keep your memorial private. </b>
                  <span>
                    Your memorial will not be visible in the large quilt or searchable by name. Your
                    memorial will not be visible on the national quilt. You will be sent a link by
                    email to access the quilt that you can share with others.
                  </span>
                </p>
              }
            />
          </RadioGroup>
        </div>

        <div className={styles.displayActions}>
          <button className={`customButton`} onClick={showDialogBlocks}>
            Preview
          </button>

          <div className={styles.displayActions__buttonText}>
            <span>I’m finished...</span>
            <button className={`customButton`} onClick={onPublish}>
              Publish
            </button>
          </div>
        </div>

        <div className={styles.info}>
          <div className={styles.title}>
            <span className="defaultTitle">Sharing Your Memorial with Others</span>
            <span className="defaultText">
              You can share via social media or email using the icons on the right edge of your
              memorial. Social media/sharing buttons are currently not available on the right edge
              of the memorials - is there a ticket for this? Look for the buttons below!
            </span>
          </div>

          <img src="/img/general/sharing-picture-1.png" alt="sharing-1" />
        </div>

        <div className={styles.info}>
          <div className={styles.title}>
            <span className="defaultTitle">Manage Tributes and Condolences</span>
            <span className="defaultText">
              With the Tributes and Condolences feature, other friends and family can add a tribute
              for your loved ones. Any unwanted tributes and condolences posted by others can be
              removed.
            </span>
          </div>

          <img src="/img/general/tributesAndCondolences-screen.png" alt="sharing-1" />
        </div>

        <div className={styles.info} style={{ marginBottom: '40px' }}>
          <div className={styles.title}>
            <span className="defaultTitle">Manage Preferences</span>
            <span className="defaultText">
              In{' '}
              <span onClick={goToProfileUser} className={styles.settings__user}>
                user settings
              </span>{' '}
              you can update profile information, update the settings for the quilts you have made,
              and see other memorials you have been given access to edit.
            </span>
          </div>
        </div>

        <div className="delimitierGrey"></div>

        <div className={styles.actions}>
          <div>
            <button className={`customButton`} onClick={backAction}>
              Back
            </button>
          </div>
          <button className={`customButton`} onClick={handleViewing}>
            View Quilt on Home Page
          </button>
          <button className={`customButton`} onClick={goToMyMemorial}>
            VIEW MY MEMORIAL
          </button>
        </div>
      </div>

      <Loader loading={loading} />
      <DefaultDialog
        open={publish.successPublish}
        setDialog={publish.setSuccessPublish}
        title={successBuilderDialog.title}
        text={successBuilderDialog.text}
        noCancelBtn
        confirmBtnText="Close"
        confirmAction={() => publish.setSuccessPublish(false)}
      ></DefaultDialog>
      <DefaultDialog
        open={errorPrivateDialog}
        setDialog={setErrorPrivateDialog}
        title={errorBuilderPrivateDialog.title}
        text={errorBuilderPrivateDialog.text}
        noCancelBtn
        confirmBtnText="OK"
        confirmAction={() => setErrorPrivateDialog(false)}
      ></DefaultDialog>
      <DefaultDialog
        open={errorPublishDialog}
        setDialog={setErrorPublishDialog}
        title={errorBuilderPublishDialog.title}
        text={errorBuilderPublishDialog.text}
        noCancelBtn
        confirmBtnText="OK"
        confirmAction={() => setErrorPublishDialog(false)}
      ></DefaultDialog>
    </div>
  );
};
