import {editUnpublishedQuilt, getQuiltsMega} from 'api/http-client/queries/quiltQueries';
import { QuiltsResponseMega } from 'api/http-client/types';
import { TDirection } from 'components/HomeCarousel/model';
import { MutableRefObject, useEffect, useState, Dispatch, SetStateAction } from 'react';
import {useMediaQuery} from "@mui/material";
import {useSearchParams} from "react-router-dom";

interface UseCarouselHomeReturnModel {
    toggleZIndex?: (control: boolean) => void,
    getMovePositionBySide?: () => string,
    handleTransitionEnd?: () => void,
    data: DataModel | undefined,
    setData: Dispatch<SetStateAction<DataModel | undefined>>,
    settingData: DataSettingModel,
    loading: boolean
}

interface UseCarouselHomeParamsModel {
    containerMoveRef: MutableRefObject<HTMLDivElement | null>
    containerMainRef: MutableRefObject<HTMLDivElement | null>
    moveSide: TDirection
    setMoveSide: Dispatch<SetStateAction<TDirection>>
}

export interface DataModel {
    clearData: QuiltsResponseMega[],
    fullData: QuiltsResponseMega[][],
    from: number,
    to: number
}

export interface DataSettingModel {
    limitMega: number;
    groupLimit: number;
    rowLimit: number;
}

const controllerGroupLimit: Record<string, number> = {
    '4': 4,
    // '5': 4,
    // '7': 6,
    // '8': 7,
    // '9': 8,
    // '10': 9
}

const mobileGroupLimit = 4

export const useCarouselHome = ({ containerMoveRef, containerMainRef, moveSide, setMoveSide } : UseCarouselHomeParamsModel) : UseCarouselHomeReturnModel => {
    const [searchParams, setSearchParams] = useSearchParams()

    const matches1240 = useMediaQuery('(max-width: 1240px)');
    const widthScreen = window.screen.width
    const groupLimit = matches1240 ? mobileGroupLimit : Math.floor(widthScreen / 277);

    const [data, setData] = useState<DataModel>()
    const [loading, setLoading] = useState<boolean>(false)
    const [settingData, setSettingData] = useState<DataSettingModel>({
      limitMega: 150,
      groupLimit: controllerGroupLimit?.[`${groupLimit}`] ?? groupLimit - 1,
      rowLimit: matches1240 ? 6 : 5
    })

    useEffect(() => {
        window.addEventListener('resize', handleResizeUpdateSettingData);

        setLoading(true)
        getQuiltsMega(settingData.limitMega).then((data) => {
            // visible functionality (view quilt on home page)
            const visibleId = searchParams.get("visible");

            if (visibleId) {
                editUnpublishedQuilt(visibleId).then(quilt => {
                    const dataWithoutThisQuilt = data.filter(item => item.id !== +visibleId)

                    setData(getPreparingData([quilt, ...dataWithoutThisQuilt]))
                })
            } else {
                setData(getPreparingData(data))
            }
        }).finally(() => setLoading(false))

        return () => {
          window.removeEventListener('resize', handleResizeUpdateSettingData);
        }
    }, [])

    useEffect(() => {
        data?.clearData && setData(getPreparingData(data.clearData))
    }, [settingData.groupLimit])

    const getPreparingData = (data: QuiltsResponseMega[]) => {
        let group: QuiltsResponseMega[] = []

        const splitData = data?.reduce((total, item) => {
            group.push(item)

            if (group.length === settingData.groupLimit) {
                total.push(group)
                group = []
            }

            return total
        }, [] as QuiltsResponseMega[][])

        const result: DataModel = {
            fullData: [...splitData],
            clearData: [...data],
            from: 0,
            to: settingData.rowLimit
        }

        return result
    }

    const handleResizeUpdateSettingData = () => {
      const widthScreen = window.screen.width
      const groupLimit = matches1240 ? mobileGroupLimit : Math.floor(widthScreen / 277);

      setSettingData((state) => {
        return {
          ...state,
          groupLimit: controllerGroupLimit?.[`${groupLimit}`] ?? groupLimit - 1,
        }
      })
    }

    // const toggleZIndex = (control: boolean) => {
    //     if (!containerMoveRef.current || !containerMainRef.current) return
    //
    //     if (control) {
    //         containerMoveRef.current.style.zIndex = '999';
    //         containerMainRef.current.style.zIndex = '0';
    //         } else {
    //         containerMoveRef.current.style.zIndex = '0';
    //         containerMainRef.current.style.zIndex = '999';
    //     }
    // }

    // const getMovePositionBySide = () => {
    //     // if (moveSide === 'center') return '0';
    //     //
    //     // return moveSide === 'right' ? "100" : "-100"
    //     return ''
    // }

    // const handleTransitionEnd = () => {
    //     if (!containerMoveRef.current) return
    //     //
    //     // containerMoveRef.current.style.transition = 'none';
    //     // toggleZIndex(false)
    //     // setMoveSide('center')
    // };

    return {
        // getMovePositionBySide,
        // handleTransitionEnd,
        data,
        loading,
        setData,
        settingData
    };
};
