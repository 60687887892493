import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useLocation, useParams, useSearchParams} from 'react-router-dom';

import {
  CreateMemorialQuiltHeader,
  CreateMemorialQuiltStepOne,
  CreateMemorialQuiltStepTwo,
  CreateMemorialQuiltStepThree,
  CreateMemorialQuiltStepFour,
} from '../../components/CreateMemorialQuiltComponents';
import { editUnpublishedQuilt } from '../../api/http-client/queries/quiltQueries';
import {setEditableQuiltState, setIsTriggerResetValuesComparing} from '../../store/reducers/quiltSlice';
import {setIsSave, setOpen} from "../../store/reducers/notifySlice";
import {useAppDispatch, useAppSelector} from '../../hooks/hooks';
import { Loader } from '../../components/Loader/Loader';
import {deepEqual, getListWithIdByListArray, notifyMessagesQuiltUpdateCreate} from '../../utilities';
import { AccessPermissionsTextEnum } from '../../components/CreateMemorialQuiltComponents/CreateMemorialQuiltStepTwo/const';

import styles from './styles.module.scss';
import { TQuilt } from "../../components/CreateMemorialQuiltComponents/CreateMemorialQuiltStepOne/model";
import {useRedirectAndScroll} from "../../hooks";
import {defaultValueList} from "../../components/CreateMemorialQuiltComponents/CreateMemorialQuiltStepOne/const";
import {useAuth} from "../../providers/auth-provider";

const steps = [
  { index: 1, title: 'Who Are You Remembering?' },
  { index: 2, title: 'Invite Others' },
  { index: 3, title: 'Build Your Quilt Square' },
  { index: 4, title: 'Finish and Share!' },
];

const CreateMemorialQuilt: React.FC = () => {
  const editableQuiltStore = useAppSelector(state => state.quilt.editableQuilt);
  const currentBlocksStore = useAppSelector(state => state.quilt.currentBlocks);
  const authUser = useAppSelector(state => state.auth.user);
  const isTriggerResetValuesComparing = useAppSelector(state => state.quilt.isTriggerResetValuesComparing);

  const { onlyRedirect } = useRedirectAndScroll({})
  const dispatch = useAppDispatch();

  const { user } = useAuth();
  const { id } = useParams();
  const location = useLocation()
  const [searchParams] = useSearchParams();
  let step: string = searchParams.get('step') || '1';

  const valuesQuiltSteps = useRef<{
    quilt: TQuilt,
    blocks: string
  }>({
    quilt: {},
    blocks: ""
  })

  const [loading, setLoading] = useState(false);

  // synchronize the variable with the store
  useEffect(() => {
    valuesQuiltSteps.current.quilt = editableQuiltStore;

    if (editableQuiltStore.memorial?.data) {
      valuesQuiltSteps.current.blocks = editableQuiltStore.memorial?.data
    }
  }, [step])

  // checking data for modal not save
  useEffect(() => {
    if (!Object.keys(valuesQuiltSteps.current.quilt)) return

    if (step === '1' && !location.pathname.includes('create')) {
      dispatch(setIsSave(deepEqual(editableQuiltStore, valuesQuiltSteps.current.quilt, ["album", "isEnableTributesAndCondolence"])))
    } else {
      dispatch(setIsSave(true))
    }
  }, [editableQuiltStore])

  useEffect(() => {
    // need after create quilt for correct comparing, because we dynamic create currentBlocksStore after first entering
    if (!valuesQuiltSteps.current.blocks && currentBlocksStore.length > 1) {
      valuesQuiltSteps.current.blocks = JSON.stringify(currentBlocksStore)
    }

    if (step === '3' && valuesQuiltSteps.current.blocks) {
      if (isTriggerResetValuesComparing) {
        dispatch(setIsSave(true))
        dispatch(setIsTriggerResetValuesComparing(false))
      } else {
        dispatch(setIsSave(JSON.stringify(currentBlocksStore).length === valuesQuiltSteps.current.blocks.length))
      }
    } else {
      dispatch(setIsSave(true))
    }
  }, [currentBlocksStore])

  useEffect(() => {
    if (id) {
      setLoading(true);

      editUnpublishedQuilt(id)
        .then((res) => {
          if (res?.access === AccessPermissionsTextEnum.EDIT_IMAGES) {
            onlyRedirect(`/add-images/${id}`);
            return;
          }

          if (res?.access === AccessPermissionsTextEnum.NO_EDIT) {
            onlyRedirect('/');
            return;
          }

          const quiltValue = {
            ...res,
            isEnableTributesAndCondolence: res?.isEnableTributesAndCondolence,
            ...(!res?.communityInvolvements?.length && { communityInvolvements: [""] }),
            ...(!res?.placesOfResidence?.length && { placesOfResidence: [""] }),
            ...(!res?.educations?.length && { educations: [""] }),
            ...(!res?.hobbiesAndPasttimes?.length && { hobbiesAndPasttimes: [""] }),
          }

          valuesQuiltSteps.current.quilt = {...quiltValue}
          valuesQuiltSteps.current.blocks = quiltValue?.memorial?.data || ""

          dispatch(setEditableQuiltState(quiltValue));
        })
        .catch((e) => {
          if (e?.errors && e?.errors[0] === 'NotOwnerNorContributor') {
          } else {
            dispatch(setOpen(notifyMessagesQuiltUpdateCreate.page.problemMemorial))
          }

          dispatch(setIsSave(true))
          onlyRedirect('/');
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      dispatch(setEditableQuiltState({}));
    }
  }, [dispatch, id]);

  const getComponentByStep = useCallback(() => {
    switch (step) {
      case '1':
        return <CreateMemorialQuiltStepOne />;
      case '2':
        return <CreateMemorialQuiltStepTwo />;
      case '3':
        return <CreateMemorialQuiltStepThree />;
      case '4':
        return <CreateMemorialQuiltStepFour />;
      default:
        return <div>Some problems with page</div>;
    }
  }, [step]);

  return (
    <>
      <div className={styles.wrapper}>
        { (!user?.isInvitedUser && !editableQuiltStore.isContributor) && <CreateMemorialQuiltHeader
          title={+step === 4 ? 'Finish and Share Your Memorial' : 'Create Your Memorial'}
          isActiveStep={+step}
          steps={steps}
          isShowInspiration={+step !== 4}
        ></CreateMemorialQuiltHeader> }

        {getComponentByStep()}
      </div>

      <Loader loading={loading} />
    </>
  );
};

export default CreateMemorialQuilt;
