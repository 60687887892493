import React, { ReactNode, useMemo } from 'react';

import { useAppSelector } from 'hooks/hooks';
import { colors, getFormattedDate } from 'utilities';

import styles from './styles.module.scss';

const tryURL = (value: string): ReactNode | string => {
  if (!value) return '';
  try {
    const string = value.trim();
    if (!/\s/.test(string) && /\./.test(string) && !/^https?:\/\//i.test(string)) {
      value = 'http://' + string;
    }
    new URL(value);
    return (
      <a href={value} target="_blank" rel="noreferrer" style={{ color: colors.blue }}>
        {value}
      </a>
    );
  } catch (error) {
    return value;
  }
};

export const HeaderInfo: React.FC = () => {
  const editorData = useAppSelector((state) => state.quilt.editableQuilt);

  const getValuesColumn1 = useMemo(() => {
    return [
      editorData?.dateOfBirth
        ? { title: 'Date of birth', value: getFormattedDate(editorData.dateOfBirth) }
        : null,
      editorData?.placesOfResidence &&
      editorData?.placesOfResidence?.length > 0 &&
      editorData?.placesOfResidence?.[0]
        ? { title: 'Place of Residence', value: editorData.placesOfResidence }
        : null,
      editorData?.hometown ? { title: 'Hometown', value: editorData.hometown } : null,
      editorData?.educations && editorData?.educations?.length > 0 && editorData?.educations?.[0]
        ? { title: 'Education', value: editorData.educations }
        : null,
      editorData?.hobbiesAndPasttimes &&
      editorData?.hobbiesAndPasttimes?.length > 0 &&
      editorData?.hobbiesAndPasttimes?.[0]
        ? { title: 'Hobbies and Pastimes', value: editorData.hobbiesAndPasttimes }
        : null,
    ];
  }, [editorData]);

  const getValuesColumn2 = useMemo(() => {
    return [
      editorData?.communityInvolvements &&
      editorData?.communityInvolvements?.length > 0 &&
      editorData?.communityInvolvements?.[0]
        ? { title: 'Community Involvement', value: editorData.communityInvolvements }
        : null,
      editorData?.dateOfPassing
        ? { title: 'Date of Death', value: getFormattedDate(editorData.dateOfPassing) }
        : null,
      editorData?.placeOfBurial
        ? { title: 'Place of Burial', value: editorData.placeOfBurial }
        : null,
      editorData?.inMemorialDonation
        ? { title: 'In memorial Donation', value: editorData.inMemorialDonation }
        : null,
      editorData?.donationLink
        ? { title: 'Donation Link', value: tryURL(editorData.donationLink) }
        : null,
    ];
  }, [editorData]);

  const getItemValue = (value: string | string[] | ReactNode): string | ReactNode => {
    if (!value) return '';

    if (Array.isArray(value)) {
      return (
        <div className={styles.arrayType}>
          {value.map((item, index) => {
            return <span key={index}>{item}</span>;
          })}
        </div>
      );
    } else return value;
  };

  const getDateUnderPhoto = useMemo(() => {
    if (!editorData?.dateOfBirth || !editorData?.dateOfPassing) return;

    return `${editorData?.dateOfBirth.year}-${editorData?.dateOfPassing.year}`;
  }, [editorData?.dateOfBirth, editorData?.dateOfPassing]);

  return (
    editorData && (
      <div className={styles.content}>
        <div className={styles.contentLeft}>
          <div className={styles.contentLeftInside}>
            {editorData.previewImageUrl ? (
              <img
                className={styles.details__addPhoto}
                src={editorData.previewImageUrl}
                alt={'preview'}
              />
            ) : editorData.finalImage?.full ? (
              <img
                className={styles.details__addPhoto}
                src={editorData.finalImage?.full}
                alt={editorData.finalImage?.name}
              />
            ) : (
              <div className={styles.details__noImageText}><span>No Image</span></div>
            )}
            <span
              className={styles.title}
            >{`${editorData.firstName || ''} ${editorData?.lastName || ''}`}</span>
            <span className={styles.text}>{getDateUnderPhoto}</span>
          </div>
        </div>

        <div className={styles.contentRight}>
          <div className={styles.contentRightColumn1}>
            {getValuesColumn1.map((item, index) => {
              return item ? (
                <div className={styles.contentItem} key={index}>
                  <span className={styles.title}>{item?.title}</span>
                  <span className={styles.text}>{getItemValue(item?.value)}</span>
                </div>
              ) : null;
            })}
          </div>

          <div className={styles.contentRightColumn2}>
            {getValuesColumn2.map((item, index) => {
              return item ? (
                <div className={styles.contentItem} key={index}>
                  <span className={styles.title}>{item?.title}</span>
                  <span className={styles.text}>{getItemValue(item?.value)}</span>
                </div>
              ) : null;
            })}
          </div>
        </div>
      </div>
    )
  );
};
