import React, { useCallback, useEffect, useState } from 'react';
import { Box, Tab, Tabs } from '@mui/material';

import {
  changeUserPassword,
  deleteQuilt,
  editableQuiltsList,
  getProfile,
  getUserQuilts,
  inviteUserToQuilt,
  removeUserAccess,
  updateProfile,
} from 'api/http-client/queries/profileQueries';
import { publicPrivate, publishDraft } from 'api/http-client/queries/quilts';
import { TProfile, TRemoveAccess, TShareQuilt, TUserQuilt } from 'api/http-client/profileTypes';
import { colors, notifyMessages } from 'utilities';
import { useTranslates } from 'providers/translates';
import { useAppDispatch, useAppSelector } from 'hooks';
import { setOpen } from 'store/reducers/notifySlice';
import { ProfileTab, setTab } from 'store/reducers/profileTabSlice';

import { AccessPermissionsTextEnum } from 'components/CreateMemorialQuiltComponents/CreateMemorialQuiltStepTwo/const';
import { Loader } from 'components/Loader/Loader';
import AdminDashboard from 'pages/AdminDashboard';

import { Welcome } from './Welcome';
import { MyMemorials } from './MyMemorials';
import { UserSettings } from './UserSettings';

import styles from './styles.module.scss';

interface TabPanelProps {
  children?: React.ReactNode;
  index: string;
  value: string;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ px: 0, py: 3 }}>{children}</Box>}
    </div>
  );
}

export const UserProfile: React.FC = () => {
  const { translates } = useTranslates();
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState<TProfile | null>(null);
  const [quiltsList, setQuiltsList] = useState<TUserQuilt[] | null>(null);
  const [editableList, setEditableList] = useState<TUserQuilt[] | null>(null);

  const storeTab = useAppSelector((state) => state.tabs);

  const requestUserData = useCallback(() => {
    return Promise.all([
      getProfile().then((resp) => setUser(resp)),
      getUserQuilts().then((resp) => setQuiltsList(resp)),
      editableQuiltsList().then((resp) => {
        // without no edit
        const editableListData = resp.filter(
          (item) => item.access !== AccessPermissionsTextEnum.NO_EDIT
        );

        setEditableList(editableListData);
      }),
    ]);
  }, []);

  useEffect(() => {
    setLoading(true);

    requestUserData().then(() => {
      setLoading(false);
    });
  }, [requestUserData]);

  const handleChange = (event: React.SyntheticEvent, newValue: ProfileTab) => {
    dispatch(setTab(newValue));
  };

  const publicUnpublic = (quilt: TUserQuilt) => {
    if (!quilt.finalImage && !quilt.isPublic) {
      dispatch(setOpen(notifyMessages.quiltPublicImageError));
      return;
    }
    // if (!quilt.isPublished && !quilt.isPublic) {
    //   dispatch(setOpen(notifyMessages.quiltPublicDraftError);
    //   return;
    // }
    setLoading(true);
    publicPrivate(quilt.id, quilt.isPublic).finally(() => {
      dispatch(setOpen(translates?.notifyMessages?.quiltChanged || notifyMessages.quiltChanged));
      requestUserData();
      setLoading(false);
    });
  };

  const publishUnpublish = (quilt: TUserQuilt) => {
    setLoading(true);
    publishDraft(quilt.id, quilt.isPublished).finally(() => {
      dispatch(setOpen(translates?.notifyMessages?.quiltChanged || notifyMessages.quiltChanged));
      requestUserData();
      setLoading(false);
    });
  };

  const shareQuilt = (body: TShareQuilt) => {
    inviteUserToQuilt(body).finally(() => {
      requestUserData();
      dispatch(setOpen(translates?.notifyMessages?.accessAllowed || notifyMessages.accessAllowed));
    });
  };
  const removeAccess = (body: TRemoveAccess) => {
    removeUserAccess(body).finally(() => {
      requestUserData();
      dispatch(setOpen(translates?.notifyMessages?.accessBlocked || notifyMessages.accessBlocked));
    });
  };
  const deleteUserQuilt = (id: string) => {
    setLoading(true);
    deleteQuilt(id).finally(() => {
      requestUserData();
      setLoading(false);
      dispatch(setOpen(translates?.notifyMessages?.quiltDeleted || notifyMessages.quiltDeleted));
    });
  };

  const saveUserData = (data: TProfile) => {
    setLoading(true);

    updateProfile(data)
      .then(() => getProfile())
      .then((resp) => {
        setUser(resp)
        dispatch(
          setOpen(translates?.notifyMessages?.savedSettings || notifyMessages.savedSettings)
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const changePassword = (password: string) => {
    changeUserPassword(password).finally(() =>
      dispatch(
        setOpen(translates?.notifyMessages?.passwordChanged || notifyMessages.passwordChanged)
      )
    );
  };

  return (
    <div className={styles.wrap}>
      {user ? (
        <Box className="center">
          <Tabs
            value={storeTab}
            onChange={handleChange}
            variant="scrollable"
            allowScrollButtonsMobile
            textColor="primary"
          >
            <Tab
              value={ProfileTab.One}
              label="Welcome"
              sx={{ color: colors.blue, fontSize: { xs: 14, sm: 18 } }}
            />
            <Tab
              value={ProfileTab.Two}
              label="My Memorials"
              sx={{ color: colors.blue, fontSize: { xs: 14, sm: 18 } }}
            />
            <Tab
              value={ProfileTab.Three}
              label="User Settings"
              sx={{ color: colors.blue, fontSize: { xs: 14, sm: 18 } }}
            />
            {user.isAdmin && (
              <Tab
                value={ProfileTab.Four}
                label="Admin Panel"
                sx={{ color: colors.blue, fontSize: { xs: 14, sm: 18 } }}
              />
            )}
          </Tabs>
          <CustomTabPanel value={storeTab} index={ProfileTab.One}>
            <Welcome user={user} />
          </CustomTabPanel>
          <CustomTabPanel value={storeTab} index={ProfileTab.Two}>
            <MyMemorials
              quiltsList={quiltsList}
              user={user}
              publicUnpublic={publicUnpublic}
              editableList={editableList}
              publishUnpublish={publishUnpublish}
              shareQuilt={shareQuilt}
              removeAccess={removeAccess}
              deleteUserQuilt={deleteUserQuilt}
            />
          </CustomTabPanel>
          <CustomTabPanel value={storeTab} index={ProfileTab.Three}>
            <UserSettings user={user} changePassword={changePassword} saveUserData={saveUserData} />
          </CustomTabPanel>
          {user.isAdmin && (
            <CustomTabPanel value={storeTab} index={ProfileTab.Four}>
              <AdminDashboard />
            </CustomTabPanel>
          )}
        </Box>
      ) : (
        <span className={styles.noUser}>No user!</span>
      )}
      <Loader loading={loading} />
    </div>
  );
};
