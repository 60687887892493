import {useState, useRef, FC, useEffect} from 'react';
import styles from "./styles.module.scss"
import { CustomRightArrow, CustomLeftArrow } from './components/Arrows';
import { TDirection } from 'components/HomeCarousel/model';
import { useCarouselHome } from 'hooks/useCarouselHome';
import MainCarouseRow from './components/Row/MainCarouseRow';
import { Loader } from 'components/Loader/Loader';

const staticWidthImage = 2746

const HomeCarousel: FC = () => {
  const [moveSide, setMoveSide] = useState<TDirection>('left')
  const [positionContainer, setPositionContainer] = useState(0)

  const containerMoveRef = useRef<HTMLDivElement | null>(null);
  const containerMainRef = useRef<HTMLDivElement | null>(null);

  const {
    data,
    setData,
    loading,
    settingData
  } = useCarouselHome({
    containerMoveRef,
    containerMainRef,
    moveSide,
    setMoveSide
  })

  const moveSlider = (direction: TDirection) => {
    const step = settingData.rowLimit

    if (direction === "left" && typeof data?.from !== 'undefined' && (data?.from - step) <= 0) {
      setData((state) => {
        if (!state || !data) return state;

        return {
          ...state,
          from: data?.fullData.length - step,
          to: data?.fullData.length,
        }
      })

      setPositionContainer(state => state - staticWidthImage)
    }

    else if (direction === "right" && data?.to && data?.fullData.length && data?.to >= data?.fullData.length) {
      setData((state) => {
        if (!state) return state;

        return {
          ...state,
          from: 0,
          to: step,
        }
      })

      setPositionContainer(state => state + staticWidthImage)
    }

    // default behavior
    else {
      setData((state) => {
        if (!state) return state;

        return {
          ...state,
          from: direction === "right" ? state.from + step : state.from - step,
          to: direction === "right" ? state.to + step : state.to - step,
        }
      })

    setPositionContainer(state => state + staticWidthImage)
    }
  }

  return (
    <div className={styles.container}>
      <div ref={containerMainRef} className={styles.slider__container} style={{
        backgroundPosition: `calc(50% - ${positionContainer}px)`
      }}>
        <div className={styles.slider__wrap}>
          <CustomLeftArrow onClick={() => moveSlider("left")}></CustomLeftArrow>
          <div className={styles.slider__content}>
            {
              data?.fullData.slice(data.from, data.to)?.map((items, index) => {
                return (
                  <MainCarouseRow key={index} items={items} indexOfRenderRow={index} settingData={settingData}></MainCarouseRow>
                )
              })
            }
          </div>
          <CustomRightArrow onClick={() => moveSlider("right")}></CustomRightArrow>
        </div>
      </div>
      <Loader loading={loading}></Loader>
    </div>
  );
}

export default HomeCarousel
