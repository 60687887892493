import styles from "./styles.module.scss";
import {ChangeEvent, Dispatch, FC, SetStateAction, useEffect, useRef, useState} from "react";
import {useLocation, useParams, useSearchParams} from "react-router-dom";
import { Collapse, Fade, FormControlLabel, Radio, TextField } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AddIcon from '@mui/icons-material/Add';
import DoneIcon from '@mui/icons-material/Done';
import HelpIcon from '@mui/icons-material/Help';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import Cropper from "cropperjs";
import {debounce} from "lodash";
import {
  createFileFromUrl,
  inputsSetting,
  notifyMessagesQuiltMemorialUpdateCreate,
  getListWithIdByListArray,
  getArrayListWithoutId,
  getFullDate,
  colors, notifyMessagesQuiltUpdateCreate,
} from "utilities";
import { useTranslates } from "providers/translates";
import { TDefaultListValue, TQuiltCreateUpdate } from "api/http-client/types";
import { editUnpublishedQuilt } from "api/http-client/queries/quiltQueries";
import {
  createQuilt,
  deleteQuiltImg,
  editQuilt,
  postPhotoToQuiltAlbum,
  uploadQuiltImg
} from "api/http-client/queries/quilts";
import { RoutesName } from "routs";
import { Loader } from "../../Loader/Loader";
import { showDialog } from "store/reducers/dialogsSlice";
import {setEditableQuiltState, setIsTriggerResetValuesComparing} from "store/reducers/quiltSlice";
import { useAppDispatch, useAppSelector, useRedirectAndScroll, useQuilt, useIsMounted } from "hooks";
import { CreateImagesBlockLocal, CreateImagesBlock } from '../components';
import { ImageTypeModel } from '../CreateMemorialQuiltStepThree/model';
import { CreateMemorialQuiltStepOneProps, TDaysDate, TFields, TFieldsDate, TLists, TQuilt } from "./model";
import { updateStateByValueInputList, createStateInputList, removeStateInputListById } from "./utils";
import { defaultValueList } from "./const";
import { DarkTooltip } from '../../Tooltips/CustomTooltips';
import {setIsSave, setOpen} from 'store/reducers/notifySlice';

interface DateInput {
  day: Date | null;
  month: Date | null;
  year: Date | null;
}

interface IArraysData {
  placesOfResidence:TQuilt['placesOfResidence'],
  educations:TQuilt['educations'],
  hobbiesAndPasttimes:TQuilt['hobbiesAndPasttimes'],
  communityInvolvements:TQuilt['communityInvolvements']
}

export const CreateMemorialQuiltStepOne: FC<CreateMemorialQuiltStepOneProps> = () => {
  const { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams()
  const location = useLocation();
  const { translates } = useTranslates();
  const dispatch = useAppDispatch();
  const editableQuiltStore = useAppSelector(state => state.quilt.editableQuilt);
  const { redirectAndScroll, onlyScroll } = useRedirectAndScroll({});
  const { publish, loading : loadingPublish } = useQuilt({ id: id || '' });
  const isMounted = useIsMounted()

  const [fileImage, setFileImage] = useState<File | null>();
  const [fileImageUrl, setFileImageUrl] = useState<string>();
  const [currentCrop, setCurrentCrop] = useState<Cropper | null>(null)
  const [loading, setLoading] = useState(false);
  const [communityInvolvements, setCommunityInvolvements] = useState<TDefaultListValue[]>(defaultValueList)
  const [placesOfResidence, setPlacesOfResidence] = useState<TDefaultListValue[]>(defaultValueList)
  const [educations, setEducations] = useState<TDefaultListValue[]>(defaultValueList)
  const [hobbiesAndPasttimes, setHobbiesAndPasttimes] = useState<TDefaultListValue[]>(defaultValueList)
  const [showAddImages, setShowAddImages] = useState(false);
  const [dateOfBirthInput, setDateOfBirthInput] = useState<DateInput>({ day: null, month: null, year: null });
  const [dateOfPassingInput, setDateOfPassingInput] = useState<DateInput>({ day: null, month: null, year: null });
  const [filesImages, setFilesImages] = useState<File[]>([]);

  const isInit = useRef(false)
  const fileInputRef = useRef<HTMLInputElement>(null);
  const imageRef = useRef<HTMLImageElement>(null);

  // reset in a create mode
  useEffect(() => {
    if (location.pathname.includes("create")) {
      dispatch(setEditableQuiltState({}))
    }
  }, [])

  // init
  useEffect(() => {
    if (!editableQuiltStore || !id || !Object.keys(editableQuiltStore).length) return;
    if (isInit.current) return;

    editableQuiltStore?.previewImageUrl ? setFileImageUrl(editableQuiltStore?.previewImageUrl) :
    editableQuiltStore?.finalImage?.full && setFileImageUrl(editableQuiltStore.finalImage.full)
    editableQuiltStore?.communityInvolvements?.length && setCommunityInvolvements(getListWithIdByListArray(editableQuiltStore.communityInvolvements))
    editableQuiltStore?.placesOfResidence?.length && setPlacesOfResidence(getListWithIdByListArray(editableQuiltStore.placesOfResidence))
    editableQuiltStore?.educations?.length && setEducations(getListWithIdByListArray(editableQuiltStore.educations))
    editableQuiltStore?.hobbiesAndPasttimes?.length && setHobbiesAndPasttimes(getListWithIdByListArray(editableQuiltStore.hobbiesAndPasttimes))

    setDateOfBirthInput({
      day: editableQuiltStore.dateOfBirth?.day ? getFullDate(editableQuiltStore.dateOfBirth) : null,
      month: editableQuiltStore.dateOfBirth?.month ? getFullDate(editableQuiltStore.dateOfBirth) : null,
      year: editableQuiltStore.dateOfBirth?.year ? getFullDate(editableQuiltStore.dateOfBirth) : null
    });
    setDateOfPassingInput({
      day: editableQuiltStore.dateOfPassing?.day ? getFullDate(editableQuiltStore.dateOfPassing) : null,
      month: editableQuiltStore.dateOfPassing?.month ? getFullDate(editableQuiltStore.dateOfPassing) : null,
      year: editableQuiltStore.dateOfPassing?.year ? getFullDate(editableQuiltStore.dateOfPassing) : null
    });

    isInit.current = true
  }, [editableQuiltStore, id])

  useEffect(() => {
    if (!imageRef.current) return

    if (currentCrop && fileImage) {
      currentCrop.replace(URL.createObjectURL(fileImage))
    } else {
      setCurrentCrop(new Cropper(imageRef.current))
    }
  }, [imageRef, fileImage, currentCrop])

  // effects for updating date in store (date, list)
  useEffect(() => {
    if (!isMounted) return
    setDateStore("dateOfPassing")
  }, [dateOfPassingInput])

  useEffect(() => {
    if (!isMounted) return
    setDateStore("dateOfBirth")
  }, [dateOfBirthInput])

  useEffect(() => {
    if (!isMounted) return

    dispatch(setEditableQuiltState({ ...editableQuiltStore, ...getArraysData() }))
  }, [
    communityInvolvements,
    placesOfResidence,
    educations,
    hobbiesAndPasttimes
  ])

  const openImageChoosing = () => {
    setShowAddImages(true);
    onlyScroll('addPhoto');
  }

  const accessibleToCreate = () => {
    if (!editableQuiltStore) return false;

    return (
      !editableQuiltStore.firstName ||
      !editableQuiltStore.lastName ||
      !dateOfBirthInput?.year ||
      !dateOfPassingInput?.year
    )
  };

  const getDateFromInput = (date: DateInput) => {
    const day = date.day ? new Date(date.day).getDate() : null;
    const month = date.month ? new Date(date.month).getMonth() : null;
    const year = date.year ? new Date(date.year).getFullYear() : 1970;
    return new Date(year, month ? month : 0, day || 1);
  };

  const getQuilt = (id: string) => {
    return editUnpublishedQuilt(id)
      .then((res) => {
        dispatch(setEditableQuiltState(res))
        res?.finalImage?.full && setFileImageUrl(res.finalImage.full)
        res?.communityInvolvements?.length ? setCommunityInvolvements(getListWithIdByListArray(res.communityInvolvements)) : setCommunityInvolvements(defaultValueList)
        res?.placesOfResidence?.length ? setPlacesOfResidence(getListWithIdByListArray(res.placesOfResidence)) : setPlacesOfResidence(defaultValueList)
        res?.educations?.length ? setEducations(getListWithIdByListArray(res.educations)) : setEducations(defaultValueList)
        res?.hobbiesAndPasttimes?.length ? setHobbiesAndPasttimes(getListWithIdByListArray(res.hobbiesAndPasttimes)) : setHobbiesAndPasttimes(defaultValueList)
      })
  };

  const uploadFinalImage = (id: string, img: File) => {
    setLoading(true);
    uploadQuiltImg(id, img)
      .then(
        (resp) => {
          resp.success &&
          dispatch(setEditableQuiltState({ ...editableQuiltStore, finalImage: resp.returned }))
          setFileImageUrl(resp.returned.full);
        }
      )
      .finally(() => setLoading(false));
  };

  const getArraysData = (): IArraysData => ({
    placesOfResidence: getArrayListWithoutId(placesOfResidence),
    educations: getArrayListWithoutId(educations),
    hobbiesAndPasttimes: getArrayListWithoutId(hobbiesAndPasttimes),
    communityInvolvements: getArrayListWithoutId(communityInvolvements),
  });

  const initEditableQuiltValuesRequest = (): TQuiltCreateUpdate => {
    if (!editableQuiltStore) return {};

    return {
      lastName: editableQuiltStore?.lastName || '',
      firstName: editableQuiltStore?.firstName || '',
      dateOfBirth: editableQuiltStore.dateOfBirth,
      dateOfPassing: editableQuiltStore.dateOfPassing,
      ...getArraysData(),
      hometown: editableQuiltStore?.hometown || '',
      placeOfBurial: editableQuiltStore?.placeOfBurial || '',
      inMemorialDonation: editableQuiltStore?.inMemorialDonation || '',
      donationLink: editableQuiltStore?.donationLink || '',
      isEnableTributesAndCondolence: editableQuiltStore?.isEnableTributesAndCondolence || false,
      isPublic: editableQuiltStore?.isPublic || true,
      isPublished: editableQuiltStore?.isPublished || false,
    }
  }

  const create = async () => {
    const id = await createQuilt(initEditableQuiltValuesRequest())
      .then((id) => id.toString())
      .catch(() => {
        dispatch(setOpen(
          translates?.notifyMessagesQuiltMemorialUpdateCreate?.quiltCreatedError ||
            notifyMessagesQuiltMemorialUpdateCreate.quiltCreatedError
        ));
      });

    return id;
  };

  const onCreate = async (nextStep?: boolean) => {
    if (!editableQuiltStore) return;
    if (editableQuiltStore.id) return editableQuiltStore.id;
    if (accessibleToCreate()) {
      dispatch(setOpen(
        translates?.notifyMessagesQuiltMemorialUpdateCreate?.quiltRequiredFieldsInfo ||
          notifyMessagesQuiltMemorialUpdateCreate.quiltRequiredFieldsInfo
      ));
      return;
    }

    if ((dateOfBirthInput.day && !dateOfBirthInput.month) || (dateOfPassingInput.day && !dateOfPassingInput.month)) {
      dispatch(setOpen(
        translates?.notifyMessagesQuiltMemorialUpdateCreate?.quiltUpdatedError ||
          notifyMessagesQuiltMemorialUpdateCreate.validateDayWithoutMonth
      ));
      return;
    };

    if (Number(dateOfBirthInput?.year) > Number(dateOfPassingInput?.year)) {
      dispatch(setOpen(
        translates?.notifyMessagesQuiltMemorialUpdateCreate?.quiltUpdatedError ||
          notifyMessagesQuiltMemorialUpdateCreate.validDateOfBirthOrDeath
      ));
      return;
    };

    if (getDateFromInput(dateOfBirthInput) > new Date() || getDateFromInput(dateOfPassingInput) > new Date()) {
      dispatch(setOpen(
        translates?.notifyMessagesQuiltMemorialUpdateCreate?.quiltUpdatedError ||
          notifyMessagesQuiltMemorialUpdateCreate.validateFutureDate
      ));
      return;
    }

    setLoading(true);

    const id = await create()
      .then((id) => {
        if (!id) return "";

        if (fileImageUrl) {
          createFileFromUrl(fileImageUrl, "image").then((image) => {
            uploadFinalImage(id, image);

            dispatch(setOpen(
              translates?.notifyMessagesQuiltMemorialUpdateCreate?.quiltCreatedSuccess ||
                notifyMessagesQuiltMemorialUpdateCreate.quiltCreatedSuccess
            ));
          })
        }

        if (filesImages.length > 0) {
          return Promise.all(filesImages.map(async (file) => postPhotoToQuiltAlbum(id, file))).then(() => {
            return id
          })
        } else {
          return id
        }
      })
      .finally(() => {
        setLoading(false);
      });

    id && editUnpublishedQuilt(id).then((res) => {
      res && dispatch(setEditableQuiltState(res));
    })

    nextStep && id && redirectAndScroll(`/update/${id}?step=2`);
    return id
  };

  const onPreview = () => {
    if (accessibleToCreate()) {
      dispatch(setOpen(
        translates?.notifyMessagesQuiltMemorialUpdateCreate?.quiltRequiredFieldsInfo ||
          notifyMessagesQuiltMemorialUpdateCreate.quiltRequiredFieldsInfo
      ));
      return;
    }

    showDialogBlocks();
  };

  const onPublish = async () => {
    const id = await onCreate()

    if (id) {
      try {
        await publish.onPublish(true, id)

        setSearchParams({
          step: '4'
        })
        setTimeout(() => {
          onlyScroll("createQuiltHeader")
        }, 100)
      } catch (e) {
        dispatch(setOpen(
          e?.errors ||
          notifyMessagesQuiltMemorialUpdateCreate.quiltCreatedError
        ))
      }
    }
  };

  const onUpdate = async () => {
    if (!editableQuiltStore) return
    if (!editableQuiltStore.id) return;

    if ((dateOfBirthInput.day && !dateOfBirthInput.month) || (dateOfPassingInput.day && !dateOfPassingInput.month)) {
      dispatch(setOpen(
        translates?.notifyMessagesQuiltMemorialUpdateCreate?.quiltUpdatedError ||
          notifyMessagesQuiltMemorialUpdateCreate.validateDayWithoutMonth
      ));
      return;
    };

    if (getDateFromInput(dateOfBirthInput) > getDateFromInput(dateOfPassingInput)) {
      dispatch(setOpen(
        translates?.notifyMessagesQuiltMemorialUpdateCreate?.quiltUpdatedError ||
          notifyMessagesQuiltMemorialUpdateCreate.validDateOfBirthOrDeath
      ));
      return;
    }

    if (getDateFromInput(dateOfBirthInput) > new Date() || getDateFromInput(dateOfPassingInput) > new Date()) {
      dispatch(setOpen(
        translates?.notifyMessagesQuiltMemorialUpdateCreate?.quiltUpdatedError ||
          notifyMessagesQuiltMemorialUpdateCreate.validateFutureDate
      ));
      return;
    }

    if (accessibleToCreate()) {
      dispatch(setOpen(
        translates?.notifyMessagesQuiltMemorialUpdateCreate?.quiltRequiredFieldsInfo ||
          notifyMessagesQuiltMemorialUpdateCreate.quiltRequiredFieldsInfo
      ));
      return;
    }

    setLoading(true)

    await editQuilt(editableQuiltStore.id, initEditableQuiltValuesRequest())
      .then(() => {
        if (fileImageUrl) {
          return createFileFromUrl(fileImageUrl, "image")
        }
      })
       .then((image) => {
          if (image) {
            console.log("IMAGE", image)
            uploadFinalImage(`${editableQuiltStore.id}`, image)
          }

         dispatch(setOpen(
           translates?.notifyMessagesQuiltMemorialUpdateCreate?.quiltUpdatedSuccess ||
           notifyMessagesQuiltMemorialUpdateCreate.quiltUpdatedSuccess
         ));
       })
       .then(() => {
         editableQuiltStore.id && getQuilt(editableQuiltStore.id).then(() => {
           setSearchParams({
             step: '2'
           })
           dispatch(setIsTriggerResetValuesComparing(true))
         })
       })
      .catch(() =>
        dispatch(setOpen(
          translates?.notifyMessagesQuiltMemorialUpdateCreate?.quiltUpdatedError ||
            notifyMessagesQuiltMemorialUpdateCreate.quiltUpdatedError
        ))
      ).finally(() => {
        setLoading(false)
      });
  };

  const addValueToList = (inputName: TLists) => {
    if (inputName === 'communityInvolvements') {
      setCommunityInvolvements((state) => {
        return createStateInputList(state)
      })
    }

    if (inputName === 'placesOfResidence') {
      setPlacesOfResidence((state) => {
        return createStateInputList(state)
      })
    }

    if (inputName === 'educations') {
      setEducations((state) => {
        return createStateInputList(state)
      })
    }

    if (inputName === 'hobbiesAndPasttimes') {
      setHobbiesAndPasttimes((state) => {
        return createStateInputList(state)
      })
    }
  }

  const removeValueFromList = (inputName: TLists, index: number) => {
    if (inputName === 'communityInvolvements') setCommunityInvolvements(() => removeStateInputListById(communityInvolvements, index))

    if (inputName === 'placesOfResidence') setPlacesOfResidence(() => removeStateInputListById(placesOfResidence, index))

    if (inputName === 'educations') setEducations(() => removeStateInputListById(educations, index))

    if (inputName === 'hobbiesAndPasttimes') setHobbiesAndPasttimes(() => removeStateInputListById(hobbiesAndPasttimes, index))
  }

  const setValueToList = (id: string | undefined, e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, inputName: 'communityInvolvements' | 'placesOfResidence' | 'educations' | 'hobbiesAndPasttimes') => {
    if (!id) return

    if (inputName === 'communityInvolvements') {
      setCommunityInvolvements((state) => {
        return updateStateByValueInputList(state, e.target.value, id)
      })
    }

    if (inputName === 'placesOfResidence') {
      setPlacesOfResidence((state) => {
        return updateStateByValueInputList(state, e.target.value, id)
      })
    }

    if (inputName === 'educations') {
      setEducations((state) => {
        return updateStateByValueInputList(state, e.target.value, id)
      })
    }

    if (inputName === 'hobbiesAndPasttimes') {
      setHobbiesAndPasttimes((state) => {
        return updateStateByValueInputList(state, e.target.value, id)
      })
    }
  }

  const changeImage = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files

    if (!files) return;

    setFileImage(files[0]);
  }

  const setImage = () => {
    if (!currentCrop) return;

    setFileImageUrl(currentCrop.getCroppedCanvas().toDataURL('image/png'))

    currentCrop.destroy()
    setCurrentCrop(null)
    setFileImage(null)
  }

  const removeImage = () => {
    if (!id) return;

    setLoading(true)

    deleteQuiltImg(id).then(() => {
      setFileImageUrl('')
      dispatch(setOpen(
        notifyMessagesQuiltUpdateCreate.one.imageDelete
      ));
    }).catch((e) => {
      if (e?.errors) {
        dispatch(setOpen(
          e.errors
        ));
      }
    }).finally(() => {
      setLoading(false)
    })
  }

  const showDialogBlocks = () => {
    dispatch(showDialog('blocks'))
  }

  const setIsEnableTributesAndCondolence = (event: any) => {
    if (!editableQuiltStore) return;

    if (!!editableQuiltStore.isEnableTributesAndCondolence) {
      dispatch(setEditableQuiltState({ ...editableQuiltStore, isEnableTributesAndCondolence: false }))
    } else {
      dispatch(setEditableQuiltState({ ...editableQuiltStore, isEnableTributesAndCondolence: !!event.target.value }))
    }
  }

  const setImageBlock = (image: ImageTypeModel | null) => {
    if (!image) {
      setShowAddImages(false)
      return;
    };

    setFileImageUrl(image.file)
    dispatch(setEditableQuiltState({ ...editableQuiltStore, previewImageUrl: image.file }))
    setShowAddImages(false)
  }

  const setValueToEditableQuilt = debounce((key: TFields, value: string) => {
    const data = { ...editableQuiltStore }

    if (!value) {
      delete data[key]
    }

    dispatch(setEditableQuiltState({ ...data, ...(value ? { [key]: value } : {}) }))
  }, 0);

  // date functionality
  const setDate = (nameDate: TFieldsDate, typeSet: TDaysDate, date: Date | null) => {
    // define action for changing
    let action: Dispatch<SetStateAction<DateInput>> = nameDate === 'dateOfPassing' ? setDateOfPassingInput : setDateOfBirthInput;

    // set date by type
    switch (typeSet) {
      case "day":
        action((d) => ({
          ...d,
          day: date
            ? d.year && d.month
              ? new Date(
                new Date(date.setFullYear(d.year.getFullYear())).setMonth(
                  d.month.getMonth()
                )
              )
              : d.year
                ? new Date(date.setFullYear(d.year.getFullYear()))
                : d.month
                  ? new Date(date.setMonth(d.month.getMonth()))
                  : new Date(date)
            : null,
        }));
        break
      case "month":
        action((d) => ({
          ...d,
          month: date
            ? d.year
              ? new Date(date.setFullYear(d.year.getFullYear()))
              : new Date(date)
            : null,
          day: d.day && date ? new Date(d.day.setMonth(date?.getMonth())) : null,
        }));
        break
      case "year":
        action((d) => ({
          ...d,
          year: date ? new Date(date) : null,
          day:
            d.day && date ? new Date(d.day.setFullYear(date?.getFullYear())) : null,
          month:
            d.month && date
              ? new Date(d.month.setFullYear(date?.getFullYear()))
              : null,
        }));
        break
    }
  }

  const setDateStore = (nameDate: TFieldsDate) => {
      const currentDate = nameDate === 'dateOfPassing' ? dateOfPassingInput : dateOfBirthInput

      if (!currentDate.day && !currentDate.month && !currentDate.year) {
        const data = { ...editableQuiltStore }

        if (data[nameDate]) {
          delete data[nameDate]
        }

        dispatch(setEditableQuiltState({
          ...data
        }))
        return
      }

      dispatch(setEditableQuiltState({
        ...editableQuiltStore,
        [nameDate]: {
          ...editableQuiltStore?.[nameDate],
          ...(currentDate.day ? {
            day: new Date(currentDate.day).getDate(),
          } : {}),
          ...(currentDate.month ? {
            month: new Date(currentDate.month).getMonth() + 1,
          } : {}),
          ...(currentDate.year ? {
            year: new Date(currentDate.year).getFullYear(),
          } : {}),
        }
      }))
  }

  const saveAndContinueAction = async () => {
    if (editableQuiltStore?.id) {
      onUpdate()
    } else {
      dispatch(setIsSave(true))
      onCreate(true)
    }
  }

  console.log(editableQuiltStore?.isEnableTributesAndCondolence)

  return (
    <div className={`center ${styles.firstBlock}`}>
      <p className={styles.startText}>
        Here you will be able to create a memorial for someone you are remembering. You can add
        their hometown, hobbies, awards, and other information. You can upload photos and invite
        others to contribute. You can create a “quilt” of photos, and add in written memories,
        stories, their obituary, or a eulogy. What you create is up to you and you can include
        whatever is meaningful.
      </p>
      <h2 className={styles.remembering__title}>Tell us about the person you are remembering</h2>
      <p className={styles.startText}>
        First name, last name, year of birth, year of death are mandatory to create a memorial. The
        person’s name, year of birth and year of death will display on your quilt square in the
        large quilt and also help your quilt square be found by others using the search feature. Day
        and month are optional, if they are completed they will display on your completed memorial
        page.
      </p>

      <div className={styles.remembering}>
        <div className={styles.name_block}>
          <div className="inputWithTitle">
            <span>
              {translates?.makeQuiltPage?.nameFieldLabel || 'First name'}
              <span className="requiredSymbol">*</span>
            </span>
            <TextField
              className="customInput"
              value={editableQuiltStore?.firstName || ''}
              onChange={({ target }) => {
                setValueToEditableQuilt('firstName', target.value);
              }}
              variant="outlined"
              placeholder={translates?.makeQuiltPage?.nameFieldPlaceholder || 'First name'}
              required
            />
          </div>

          <div className="inputWithTitle">
            <span>
              LAST NAME<span className="requiredSymbol">*</span>
            </span>
            <TextField
              className="customInput"
              value={editableQuiltStore?.lastName || ''}
              onChange={({ target }) => {
                setValueToEditableQuilt('lastName', target.value);
              }}
              variant="outlined"
              placeholder={translates?.makeQuiltPage?.nameFieldPlaceholder || 'Last name'}
              required
            />
          </div>
        </div>

        <div className={styles.date_block}>
          <div className={styles.date_box}>
            <p className={styles.date_box__title}>Date of birth</p>
            <div className={styles.date_wrapper}>
              <div className="inputWithTitle">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    inputFormat="dd"
                    views={['day']}
                    renderInput={(params) => (
                      <TextField className="datePickerInput customSvgIconDatePicker" {...params} />
                    )}
                    value={dateOfBirthInput.day}
                    onChange={(date) => setDate('dateOfBirth', 'day', date)}
                  />
                </LocalizationProvider>
              </div>
              <div className="inputWithTitle">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    inputFormat="MMM"
                    disableMaskedInput
                    views={['month']}
                    renderInput={(params) => (
                      <TextField className="datePickerInput customSvgIconDatePicker" {...params} />
                    )}
                    value={dateOfBirthInput.month}
                    onChange={(date) => setDate('dateOfBirth', 'month', date)}
                  />
                </LocalizationProvider>
              </div>
              <div className="inputWithTitle">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    inputFormat="yyyy"
                    views={['year']}
                    value={dateOfBirthInput.year}
                    onChange={(date) => setDate('dateOfBirth', 'year', date)}
                    renderInput={(params) => (
                      <TextField
                        className="datePickerInput customSvgIconDatePicker"
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: 'yyyy*',
                        }}
                      />
                    )}
                    maxDate={dateOfPassingInput.year || new Date()}
                  />
                </LocalizationProvider>
              </div>
            </div>
          </div>

          <div className={styles.date_box}>
            <p className={styles.date_box__title}>Date of death</p>
            <div className={styles.date_wrapper}>
              <div className="inputWithTitle">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    inputFormat="dd"
                    views={['day']}
                    renderInput={(params) => (
                      <TextField className="datePickerInput customSvgIconDatePicker" {...params} />
                    )}
                    value={dateOfPassingInput.day}
                    onChange={(date) => setDate('dateOfPassing', 'day', date)}
                  />
                </LocalizationProvider>
              </div>
              <div className="inputWithTitle">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    inputFormat="MMM"
                    disableMaskedInput
                    views={['month']}
                    renderInput={(params) => (
                      <TextField className="datePickerInput customSvgIconDatePicker" {...params} />
                    )}
                    value={dateOfPassingInput.month}
                    onChange={(date) => setDate('dateOfPassing', 'month', date)}
                  />
                </LocalizationProvider>
              </div>
              <div className="inputWithTitle">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    inputFormat="yyyy"
                    views={['year']}
                    value={dateOfPassingInput.year}
                    onChange={(date) => setDate('dateOfPassing', 'year', date)}
                    renderInput={(params) => (
                      <TextField
                        className="datePickerInput customSvgIconDatePicker"
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: 'yyyy*',
                        }}
                      />
                    )}
                    minDate={dateOfBirthInput.year || undefined}
                    maxDate={new Date()}
                  />
                </LocalizationProvider>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.mandatoryText}>
        <p>
          <span className="requiredSymbol">*</span>Mandatory
        </p>
        <DarkTooltip
          title={
            <span>
              Name, Year of Birth, and Year of Death is mandatory so that your loved one's memorial
              may be found by others if published publicly
            </span>
          }
        >
          <HelpIcon sx={{ width: '20px', color: colors.blue }} />
        </DarkTooltip>
      </div>

      <div className={styles.details}>
        <div className={styles.details__info}>
          <span className={styles.details__title}>Add a Bio Photo and Details</span>
          <p className={styles.startText}>
            Add a bio photo of the person you are remembering. This is the image that will display
            on the national quilt. All fields below are optional, complete any of the following
            details you would like to appear. Any fields left blank will not show on your memorial.
          </p>
        </div>
        <div id="addPhoto">
          {id ? (
            <Collapse in={showAddImages} timeout={1000}>
              <CreateImagesBlock setImageBlock={setImageBlock} isStepOne={true}></CreateImagesBlock>
            </Collapse>
          ) : (
            <Collapse in={showAddImages} timeout={1000}>
              <CreateImagesBlockLocal
                filesImages={filesImages}
                setFilesImages={setFilesImages}
                setImageBlock={setImageBlock}
                isStepOne={true}
              ></CreateImagesBlockLocal>
            </Collapse>
          )}
        </div>

        <div className={styles.details__infoActions}>
          {!showAddImages && (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <p className={styles.details__addPhoto_title}>Add photo</p>
              <div className={styles.details__addPhoto}>
                <input
                  type="file"
                  id="fileInputAddImage"
                  ref={fileInputRef}
                  onChange={changeImage}
                  accept="image/png, image/jpeg"
                  style={{
                    display: 'none',
                  }}
                />

                {fileImageUrl ? (
                  <img src={fileImageUrl} alt="uploaded" />
                ) : fileImage ? (
                  <img ref={imageRef} src={URL.createObjectURL(fileImage)} alt={fileImage?.name} />
                ) : (
                  <div className={styles.details__addPhotoInside}>
                    <span>Click to add a photo</span>
                    <div className={styles.details__addPhotoInsideSvg} onClick={openImageChoosing}>
                      <AddIcon></AddIcon>
                    </div>
                  </div>
                )}
                {fileImageUrl && (
                  <div className={styles.details__addPhotoActions}>
                    <div className={styles.details__addPhotoActionSvg} onClick={openImageChoosing}>
                      <EditIcon />
                    </div>

                    {!currentCrop ? (
                      <div className={styles.details__addPhotoActionSvg} onClick={removeImage}>
                        <DeleteOutlineIcon />
                      </div>
                    ) : (
                      <div
                        style={{
                          height: '30px',
                          width: '30px',
                        }}
                      ></div>
                    )}
                  </div>
                )}
                {currentCrop && (
                  <div className={styles.details__addPhotoActions} onClick={setImage}>
                    <div className={styles.details__addPhotoActionSvg}>
                      <DoneIcon></DoneIcon>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}

          <div className={styles.details__inputs}>
            <div className={styles.details__columnInput}>
              <div className={styles.details__input_wrapper}>
                <p className={styles.details__input_title}>Place of Residence</p>
                <div className={styles.details__input}>
                  {placesOfResidence.map((placeOfResidence, index) => {
                    return (
                      <Fade in={!!placeOfResidence.id} timeout={1000} key={index}>
                        <div className={styles.details__inputWrap}>
                          <div className="inputWithTitle">
                            <TextField
                              value={placeOfResidence.value || ''}
                              className="customInput"
                              variant="outlined"
                              placeholder="Enter information here"
                              required
                              sx={inputsSetting.sxWidth}
                              onChange={(e) => setValueToList(placeOfResidence.id, e, 'placesOfResidence')}
                            />
                          </div>

                          {index === 0 ? (
                            <div
                              className={styles.details__inputSvg}
                              onClick={() => addValueToList('placesOfResidence')}
                            >
                              <AddIcon></AddIcon>
                            </div>
                          ) : (
                            <div
                              className={styles.details__inputSvg}
                              onClick={() => removeValueFromList('placesOfResidence', index)}
                            >
                              <DeleteOutlineOutlinedIcon></DeleteOutlineOutlinedIcon>
                            </div>
                          )}
                        </div>
                      </Fade>
                    );
                  })}
                </div>
              </div>

              <div className={styles.details__input}>
                <div className="inputWithTitle">
                  <span>Hometown</span>
                  <TextField
                    className="customInput"
                    value={editableQuiltStore?.hometown || ''}
                    onChange={({ target }) => {
                      setValueToEditableQuilt('hometown', target.value);
                    }}
                    variant="outlined"
                    placeholder={
                      translates?.makeQuiltPage?.nameFieldPlaceholder || 'Enter information here'
                    }
                    required
                  />
                </div>
              </div>

              <div className={styles.details__input_wrapper}>
                <p className={styles.details__input_title}>Education</p>
                <div className={styles.details__input}>
                  {educations.map((education, index) => {
                    return (
                      <Fade in={!!education.id} timeout={1000} key={index}>
                        <div className={styles.details__inputWrap}>
                          <div className="inputWithTitle">
                            <TextField
                              value={education.value || ''}
                              className="customInput"
                              variant="outlined"
                              placeholder="Enter information here"
                              required
                              sx={inputsSetting.sxWidth}
                              onChange={(e) =>
                                setValueToList(education.id, e, 'educations')
                              }
                            />
                          </div>

                          {index === 0 ? (
                            <div
                              className={styles.details__inputSvg}
                              onClick={() => addValueToList('educations')}
                            >
                              <AddIcon></AddIcon>
                            </div>
                          ) : (
                            <div
                              className={styles.details__inputSvg}
                              onClick={() => removeValueFromList('educations', index)}
                            >
                              <DeleteOutlineOutlinedIcon></DeleteOutlineOutlinedIcon>
                            </div>
                          )}
                        </div>
                      </Fade>
                    );
                  })}
                </div>
              </div>

              <div className={styles.details__input_wrapper}>
                <p className={styles.details__input_title}>Hobbies and pastimes</p>
                <div className={styles.details__input}>
                  {hobbiesAndPasttimes.map((hobbyAndPastime, index) => {
                    return (
                      <Fade in={!!hobbyAndPastime.id} timeout={1000} key={index}>
                        <div className={styles.details__inputWrap}>
                          <div className="inputWithTitle">
                            <TextField
                              value={hobbyAndPastime.value || ''}
                              className="customInput"
                              variant="outlined"
                              placeholder="Enter information here"
                              required
                              sx={inputsSetting.sxWidth}
                              onChange={(e) =>
                                setValueToList(hobbyAndPastime.id, e, 'hobbiesAndPasttimes')
                              }
                            />
                          </div>

                          {index === 0 ? (
                            <div
                              className={styles.details__inputSvg}
                              onClick={() => addValueToList('hobbiesAndPasttimes')}
                            >
                              <AddIcon></AddIcon>
                            </div>
                          ) : (
                            <div
                              className={styles.details__inputSvg}
                              onClick={() => removeValueFromList('hobbiesAndPasttimes', index)}
                            >
                              <DeleteOutlineOutlinedIcon></DeleteOutlineOutlinedIcon>
                            </div>
                          )}
                        </div>
                      </Fade>
                    );
                  })}
                </div>
              </div>
            </div>

            <div className={styles.details__columnInput}>
              <div className={styles.details__input_wrapper}>
                <p className={styles.details__input_title}>Community involvement</p>
                <div className={styles.details__input}>
                  {communityInvolvements.map((communityInvolvement, index) => {
                    return (
                      <Fade in={!!communityInvolvement.id} timeout={1000} key={index}>
                        <div className={styles.details__inputWrap}>
                          <div className="inputWithTitle">
                            <TextField
                              value={communityInvolvement.value || ''}
                              className="customInput"
                              variant="outlined"
                              placeholder="Enter information here"
                              required
                              sx={inputsSetting.sxWidth}
                              onChange={(e) =>
                                setValueToList(communityInvolvement.id, e, 'communityInvolvements')
                              }
                            />
                          </div>

                          {index === 0 ? (
                            <div
                              className={styles.details__inputSvg}
                              onClick={() => addValueToList('communityInvolvements')}
                            >
                              <AddIcon></AddIcon>
                            </div>
                          ) : (
                            <div
                              className={styles.details__inputSvg}
                              onClick={() => removeValueFromList('communityInvolvements', index)}
                            >
                              <DeleteOutlineOutlinedIcon></DeleteOutlineOutlinedIcon>
                            </div>
                          )}
                        </div>
                      </Fade>
                    );
                  })}
                </div>
              </div>

              <div className={styles.details__input}>
                <div className="inputWithTitle">
                  <span>Place of burial</span>
                  <TextField
                    className="customInput"
                    value={editableQuiltStore?.placeOfBurial || ''}
                    onChange={({ target }) => {
                      setValueToEditableQuilt('placeOfBurial', target.value);
                    }}
                    variant="outlined"
                    placeholder="Enter information here"
                    required
                    sx={inputsSetting.sxWidth}
                  />
                </div>
              </div>

              <div className={styles.details__input}>
                <div className="inputWithTitle">
                  <span>In memorial Donation</span>
                  <TextField
                    className="customInput"
                    value={editableQuiltStore?.inMemorialDonation || ''}
                    onChange={({ target }) => {
                      setValueToEditableQuilt('inMemorialDonation', target.value);
                    }}
                    variant="outlined"
                    placeholder="Charity name"
                    sx={inputsSetting.sxWidth}
                  />
                </div>
              </div>

              <div className={styles.details__input}>
                <div className="inputWithTitle">
                  <span>DONATION LINK</span>
                  <TextField
                    className="customInput"
                    value={editableQuiltStore?.donationLink || ''}
                    onChange={({ target }) => {
                      setValueToEditableQuilt('donationLink', target.value);
                    }}
                    variant="outlined"
                    placeholder="Link to donate (copy and paste donation URL)"
                    required
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.tributes}>
          <div className={styles.tributes__info}>
            <span className={styles.tributes__title}>Tributes and Condolences</span>
            <p className={styles.startText}>
              Choose if you would like to allow visitors to leave tributes and condolences. You will
              be able to view and remove any added condolences on your published memorial.
            </p>
          </div>

          <div className={styles.tributes__radio}>
            <FormControlLabel
              control={
                <Radio
                  checked={editableQuiltStore?.isEnableTributesAndCondolence === true}
                  onClick={setIsEnableTributesAndCondolence}
                  value="true"
                />
              }
              componentsProps={{
                typography: {
                  color: '#333333',
                  fontSize: { xs: '18px', sm: '22px' },
                  fontFamily: 'Open Sans',
                },
              }}
              label="Allow tributes and condolences"
            />
          </div>
        </div>

        <div className={styles.tributes__example}>
          <span className={styles.tributes__exampleTitle}>Example:</span>
          <div className={styles.tributes__exampleInfo}>
            <img src="/img/general/candle-icon.svg" alt="text" />
            <div className={styles.tributes__exampleText}>
              <span>John and Mary Smith</span>
              <span>
                Our hearts are saddened by your loss and our thoughts and prayers are with you.
              </span>
            </div>
          </div>
        </div>

        <div className={`delimitierGrey ${styles.example__line}`}></div>

        <div className={styles.actions}>
          <div className={styles.actions__left}>
            <button
              className={`customButton ${styles.actions__button}`}
              onClick={() => redirectAndScroll('/')}
            >
              Back
            </button>
            <div className={styles.actions__buttonText}>
              <span>I want to add more content...</span>
              <button
                className={`customButton ${styles.actions__button}`}
                onClick={saveAndContinueAction}
              >
                Save and continue
              </button>
            </div>
          </div>
          <div className={styles.actions__right}>
            <button className={`customButton ${styles.actions__button}`} onClick={onPreview}>
              Preview
            </button>
            {!location.pathname.includes("create") && (
              <div className={styles.actions__buttonText}>
                <span>I’m finished...</span>
                <button className={`customButton ${styles.actions__button}`} onClick={onPublish}>
                  Publish
                </button>
              </div>
            )}
          </div>
        </div>
      </div>

      <Loader loading={loading || loadingPublish} />
    </div>
  );
};
