import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Fade, Select, SvgIcon, TextField } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import AddIcon from '@mui/icons-material/Add';

import {
  emailRegex,
  getSelectOptions,
  inputsSetting,
  notifyMessagesQuiltUpdateCreate,
} from 'utilities';
import { AddUsersInputsModel } from '../CreateMemorialQuiltStepTwo/model';
import { AccessPermissionsTextEnum, optionsAddUsers } from '../CreateMemorialQuiltStepTwo/const';
import { getProfile, inviteUserToQuilt } from '../../../api/http-client/queries/profileQueries';
import { Loader } from '../../Loader/Loader';
import { useAppDispatch } from 'hooks';
import { setIsNeedUpdateUsers } from 'store/reducers/usersSlice';
import { setOpen } from 'store/reducers/notifySlice';

import styles from '../CreateMemorialQuiltStepTwo/styles.module.scss';

interface AddUsersProps {
  pageName?:string
  // addUsersInputs: AddUsersInputsModel[];
  // setAddUsersInputs: Dispatch<SetStateAction<AddUsersInputsModel[]>>
  id: string;
}

const createInvite = (email?: string,access?:AccessPermissionsTextEnum)=>({ id: uuidv4(), email: email || '', access: access || AccessPermissionsTextEnum.EDIT_IMAGES })

export const AddUsers: FC<AddUsersProps> = ({ id, pageName }) => {
  const [addUsersInputs, setAddUsersInputs] = useState<AddUsersInputsModel[]>([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch()

  const inputRef = useRef<HTMLInputElement | null>(null);
  const [initialText,setInitialText] = useState('');
  const [message, setMessage] = useState(initialText);
  const [userInput, setUserInput] = useState('');
  const [userInvite, setUserInvite] = useState ({email: '', access: AccessPermissionsTextEnum.EDIT_IMAGES_MEMORIAL})

  const generateMessage = (ownerName: string, optionSelected?: string) => {
    return `Hello, \n\n${ ownerName || '' } has invited you to [add photos to/add photos and edit] a page they are creating on the [Healing Quilt to honor/remember] [page name].\n\n`
  }

  const getUser = () => {
      getProfile().then((resp) => {
      setInitialText(generateMessage(`${resp?.firstName}${resp?.lastName}`))
    });
  };

  useEffect(()=>{
    getUser()
  }, [])

  useEffect(() => {
    if (!message.startsWith(initialText)) {
      setMessage(initialText + userInput);
    }
  }, [message, userInput,initialText]);

  const handleChangeMessage = (event:React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const newText = event.target.value;

    // Ensure the initial text is always at the start
    if (newText.startsWith(initialText)) {
      setUserInput(newText.substring(initialText.length));
    } else {
      // If the user deletes part of the initial text, reset to initial text
      setMessage(initialText+userInput);
      setUserInput('');
      return;
    }
    setMessage(newText);
  };

  const setUserInfo = (email: string, e: any, field: 'email' | 'access') => {
    setAddUsersInputs((state) => {
      const indexEmail = state.findIndex((item) => item.email === email);

      state[indexEmail][field] = e.target.value as never;

      return [...state];
    });
  };

  const getOptionsAddUsers = useCallback(
    (access: AccessPermissionsTextEnum) => {
      return getSelectOptions(optionsAddUsers, `${access}`);
    },
    [addUsersInputs]
  );

  const addNewInputUser = (email?:string,access?:AccessPermissionsTextEnum) => {
    setAddUsersInputs((state) => {
      return [...state, createInvite(email,access)];
    });
    setUserInvite({email:'',access: AccessPermissionsTextEnum.EDIT_IMAGES})
  };

  const sendMessage = () => {
    if (!message) {
      dispatch(setOpen(notifyMessagesQuiltUpdateCreate.two.noMessage));
      return;
    }

    const invitedEmails = !!userInvite.email ?
    [createInvite(userInvite.email,userInvite.access), ...addUsersInputs] : addUsersInputs

    if (invitedEmails.length <= 0) {
      dispatch(setOpen(notifyMessagesQuiltUpdateCreate.two.noEmails));
      return;
    }

    const allInvites: Promise<void>[] = [];
    const wrongEmails: string[] = []

    invitedEmails.forEach((addUserInput) => {

      if (emailRegex.test(addUserInput.email)) {
        allInvites.push(
          inviteUserToQuilt({
            email: addUserInput.email,
            access: AccessPermissionsTextEnum.EDIT_IMAGES_MEMORIAL,
            message:userInput,
            id: id || '',
          })
        );
      } else {
        wrongEmails.push(addUserInput.email)
      }
    });

    setLoading(true);

    Promise.all(allInvites)
      .then(() => {
        dispatch(setOpen(notifyMessagesQuiltUpdateCreate.two.success));

        if (wrongEmails.length > 0) dispatch(setOpen(notifyMessagesQuiltUpdateCreate.two.wrongEmails(wrongEmails)));
      })
      .catch((e) => {
        dispatch(setOpen(e?.errors));
      })
      .finally(() => {
        setUserInvite({email:'',access: AccessPermissionsTextEnum.EDIT_IMAGES_MEMORIAL})
        setLoading(false);
        setAddUsersInputs([]);
        setMessage(initialText);
        dispatch(setIsNeedUpdateUsers(true))
      });
  };

const moveCursorToEnd = () => {
  // const inputMessage = document.getElementById('inputMessage') as HTMLInputElement | HTMLTextAreaElement | null;

  if (!!inputRef.current) {
    inputRef.current.selectionStart = message.length;
    inputRef.current.selectionEnd = message.length;
  }
};

  return (
    <div className={styles.addUser}>
      <div className="inputWithTitle">
        <span>Add Users</span>
      </div>
      {addUsersInputs.length ? (
        <div className={styles.addUser__block}>
          <div className={styles.addUser__blockInput}>
            {addUsersInputs.map((addUsersInput) => {
              return (
                <Fade
                  in={!!(typeof addUsersInput.email === 'string')}
                  timeout={1000}
                  key={addUsersInput.id}
                >
                  <div className={styles.addUser__blockInput2} key={addUsersInput.id}>
                    <div className="inputWithTitle">
                      {/* {index === 0 && <span>Add Users</span>} */}
                      <TextField
                        className="customInput"
                        variant="outlined"
                        placeholder="Email address"
                        required
                        value={addUsersInput?.email || ''}
                        onChange={(e) => setUserInfo(addUsersInput?.email, e, 'email')}
                        type="email"
                        key={addUsersInput.id}
                        sx={{ minWidth: { md: '100%', lg: '550px' } }}
                      />
                    </div>
                    <div className={styles.addUser__right}>
                      <div className={styles.addUser__addIcon} onClick={() => { setAddUsersInputs(users=>users.filter(user=>addUsersInput.id!==user.id))}}>
                        <DeleteOutlineOutlinedIcon></DeleteOutlineOutlinedIcon>
                      </div>
                    </div>
                  </div>
                </Fade>
              );
            })}
          </div>
        </div>
      ) : null}

      <div className={styles.addUser__blockDisable}>
        <div className={styles.addUser__blockDisableTop}>
          <div className={styles.addUser__blockDisableTopEmailWrap}>

            <TextField
              className="customInput"
              variant="outlined"
              placeholder="Email address"
              sx={inputsSetting.sxWidth}
              value={userInvite.email || ''}
              onChange={(e)=>setUserInvite(prev=>({...prev, email:e.target.value}))}
            />
          </div>
          <div className={styles.addUser__right}>
            <div className={styles.addUser__addIcon} onClick={()=>addNewInputUser(userInvite.email,userInvite.access)} >
              <AddIcon></AddIcon>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.addUser__blockDisableBottom}>
      <TextField
        inputRef={inputRef}
        id='inputMessage'
        onClick={moveCursorToEnd}
        value={message}
        onChange={handleChangeMessage}
        className="customInput"
        variant="outlined"
        required
        sx={{
          ...inputsSetting.sxWidth,
          '& .MuiInputBase-input': {
            color: message === initialText ? 'gray' : 'black',
          },
          '& .MuiInputBase-input:focus-visible': {
            color: 'black',
          },
        }}
        multiline
        minRows={8}
        maxRows={12}
        placeholder={message}
      />
      </div>

      <div className={styles.addUser__blockDisableBottomAction}>
        <button className="customButton" onClick={sendMessage}>
          SEND INVITE
        </button>
      </div>

      <Loader loading={loading} />
    </div>
  );
};
