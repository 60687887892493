import React from 'react';

import { TProfile } from 'api/http-client/profileTypes';
import { useRedirectAndScroll } from 'hooks';

import styles from './styles.module.scss';

export const Welcome: React.FC<{ user: TProfile | null }> = ({ user }) => {
  const { redirectAndScroll } = useRedirectAndScroll({});

  return (
    <section className={styles.welcome_container}>
      <div className={styles.text_wrapper}>
        <h2 className={styles.tab_title}>
          Welcome {user?.firstName}. Inviting welcome message and friendly instructions go here.
        </h2>
        <p className={styles.welcome_text}>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Ab, earum. Sequi consequuntur
          nisi pariatur. Non atque cupiditate neque quis. Ipsum? Iste voluptas numquam incidunt et
          doloribus recusandae voluptate earum accusantium.
        </p>
        <p className={styles.welcome_text}>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Quidem est blanditiis illo dolore
          magnam. Nulla esse minus molestias ipsum eius? Inventore minima incidunt officiis
          exercitationem fugiat optio quia. Architecto voluptatem, impedit ex necessitatibus
          sapiente.
        </p>
        <button
          className={`customButton ${styles.start_button}`}
          onClick={() => redirectAndScroll('/create?step=1')}
        >
          Start Now
        </button>
      </div>
      <img className={styles.image} src="/img/general/person-6-border.png" alt="Resources" />
    </section>
  );
};
