import { Collapse, Divider } from '@mui/material'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import styles from '../styles.module.scss'
import { TTributeRecord } from 'api/http-client/types';
import { useState } from 'react';
import { useTranslates } from 'providers/translates';
import TributeIcons from '../icons';

interface IShowTributesProps {
    tributes: TTributeRecord[];
    editable?:boolean;
    deleteTributesAndCondolence: (id?: string) => void
}

export const ShowTributes = ({ tributes,editable,deleteTributesAndCondolence }: IShowTributesProps) => {

    const [showAllTributes, setShowAllTributes] = useState(false)
    const { translates } = useTranslates();
    const transl = translates?.tributesAndCondolences;


    return <div className={styles.tributes}>
        <h2 className={styles.title}>{transl?.title || 'Tributes and Condolences'}</h2>

        {tributes.length > 0 ?
            <>
                {tributes.slice(0, 3).map((record) => {
                    return (
                        <span key={record.id}>
                            <div className={styles.record}>
                                <div className={styles.imgWrapper}>
                                    {!record?.icon ? null : <img className={styles.icon} src={TributeIcons?.[record?.icon]?.icon || ''} alt={'Tributes and Condolences'} />}
                                </div>

                                <div className={styles.textBox}>
                                    <h3>{`${record.firstName} ${record.lastName}`}</h3>
                                    <i>{record.message}</i>
                                </div>
                                {editable &&
                                    <div className={styles.delete} onClick={() => { deleteTributesAndCondolence(record.id) }}>
                                        <DeleteOutlineIcon />
                                    </div>}
                            </div>
                            <Divider />
                        </span>
                    )
                })}
                <Collapse in={showAllTributes} timeout={700}>
                    {tributes.slice(3).map((record) => {
                        return (
                            <span key={record.id}>
                                <div className={styles.record}>
                                    <div className={styles.imgWrapper}>
                                        {(record?.icon === 'none' || !record?.icon) ? null : <img className={styles.icon} src={TributeIcons[record.icon].icon || ''} alt={'Tributes and Condolences'} />}
                                    </div>
                                    <div className={styles.textBox}>
                                        <h3>{`${record.firstName} ${record.lastName}`}</h3>
                                        <i>{record.message}</i>
                                    </div>
                                    {editable &&
                                        <div className={styles.delete} onClick={() => { deleteTributesAndCondolence(record.id) }}>
                                            <DeleteOutlineIcon />
                                        </div>}
                                </div>
                                <Divider />
                            </span>
                        )
                    })}
                </Collapse>
            </>
            : <p>Tributes and Condolences have not been created yet</p>}
        {tributes.length > 3 &&
            <div className={styles.loadMore}>
                <button className={`customButton ${styles.btn}`} onClick={()=>{setShowAllTributes(show => !show)}}>
                    {showAllTributes ?
                        <>Show Less <ArrowDropUpIcon className={styles.actionArow} fontSize={'large'} sx={{ color: '#1D486F' }} /> </> :
                        <>Load More <ArrowDropDownIcon className={styles.actionArow} fontSize={'large'} sx={{ color: '#1D486F' }} /></>}
                </button>
            </div>}
    </div>
}
