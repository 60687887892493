import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Box, Grid, IconButton, TextField } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';

import {
  colors,
  containOneDigitRegex,
  containOneLowerCaseRegex,
  containOneSpecialCharRegex,
  containOneUpperCaseRegex,
  passwordRegex,
} from 'utilities';
import { useTranslates } from 'providers/translates';
import { DarkTooltip } from '../Tooltips/CustomTooltips';

import styles from 'pages/ResetPassword/resetPassword.module.scss';

export const PasswordForm: React.FC<{ changePassword: (password: string) => void }> = ({
  changePassword,
}) => {
  const location = useLocation();
  const { translates } = useTranslates();
  const [showPass_1, setShowPass_1] = useState(false);
  const [showPass_2, setShowPass_2] = useState(false);
  const [passwordError, setPasswordError] = useState('');

  const { control, handleSubmit, watch } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      first: '',
      second: '',
    },
  });

  const onSubmit: SubmitHandler<{ first: string; second: string }> = ({ second }) => {
    changePassword(second);
  };

  const getIsReset = () => location.pathname === '/reset';

  const checkPassword = (password: string) => {
    setPasswordError('');
    if (password.length === 0) {
      setPasswordError('Password is required');
      return;
    }
    if (password.length < 6) {
      setPasswordError('Password must be at least 6 characters');
      return;
    }
    if (!containOneDigitRegex.test(password)) {
      setPasswordError('Password must contain at least one digit');
      return;
    }
    if (!containOneLowerCaseRegex.test(password)) {
      setPasswordError('Password must contain at least one lowercase letter');
      return;
    }
    if (!containOneUpperCaseRegex.test(password)) {
      setPasswordError('Password must contain at least one uppercase letter');
      return;
    }
    if (!containOneSpecialCharRegex.test(password)) {
      setPasswordError('Password must contain at least one special character');
      return;
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container rowSpacing={{ xs: 1, md: 2 }} columnSpacing={4}>
        {!getIsReset && (
          <Grid item xs={12} md={6} lg={5} xl={4}>
            <Controller
              name="first"
              control={control}
              rules={{ required: true, pattern: passwordRegex }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <div className={`inputWithTitle ${styles.input_withTitle}`}>
                  <span>
                    {'Old password'}
                    <span className="requiredSymbol">*</span>
                  </span>

                  <TextField
                    className="customInput"
                    error={!!error}
                    fullWidth
                    // value={value}
                    onChange={(e) => onChange(e.target.value.trim())}
                    helperText={
                      !!error
                        ? `${translates?.login?.passwordHelperTextError || 'Incorrect password'}`
                        : ''
                    }
                    type={showPass_1 ? 'text' : 'password'}
                    placeholder="Enter Password"
                  />
                </div>
              )}
            />
          </Grid>
        )}

        <Grid item xs={12} md={6} lg={5} xl={4}>
          <Controller
            name="first"
            control={control}
            rules={{
              required: { value: true, message: 'Password is required' },
              pattern: passwordRegex,
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <div className={`inputWithTitle ${styles.input_withTitle}`}>
                <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
                  <span>
                    {translates?.changePassword?.changePasswordBtnLabel || 'New password'}
                    <span className="requiredSymbol">*</span>
                  </span>
                  <DarkTooltip
                    title={
                      translates?.login?.passwordTooltipRequirements ||
                      'Password needs to be at least 6 characters and needs to include one lower case letter, one upper case letter, one number and one special character.'
                    }
                  >
                    <HelpIcon sx={{ color: colors.blue }} />
                  </DarkTooltip>
                </div>
                <TextField
                  className="customInput"
                  error={!!error || !!passwordError}
                  fullWidth
                  value={value}
                  onChange={(e) => onChange(e.target.value.trim())}
                  onBlur={(e) => checkPassword(e.target.value.trim())}
                  helperText={
                    !!passwordError
                      ? passwordError
                      : !!error
                        ? `${translates?.login?.passwordHelperTextError || error.message || 'Incorrect password'}`
                        : ''
                  }
                  type={showPass_1 ? 'text' : 'password'}
                  placeholder="Enter Password"
                  InputProps={{
                    endAdornment: (
                      <DarkTooltip
                        title={
                          showPass_1
                            ? `${translates?.login?.passwordTooltipTitleHide || 'Hide password'}`
                            : `${translates?.login?.passwordTooltipTitleShow || 'Show password'}`
                        }
                      >
                        <IconButton onClick={() => setShowPass_1(!showPass_1)}>
                          {showPass_1 ? <VisibilityOffIcon /> : <VisibilityIcon color="inherit" />}
                        </IconButton>
                      </DarkTooltip>
                    ),
                  }}
                  FormHelperTextProps={{ style: { fontSize: 16 } }}
                />
              </div>
            )}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={5} xl={4}>
          <Controller
            name="second"
            control={control}
            rules={{
              required: { value: true, message: 'Password is required' },
              validate: (val: string) => {
                if (watch('first') !== val) {
                  return 'Password does not match';
                }
              },
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <div className={`inputWithTitle ${styles.input_withTitle}`}>
                <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
                  <span>
                    {translates?.changePassword?.confirmPasswordFieldLabel ||
                      'Confirm new password'}
                    <span className="requiredSymbol">*</span>
                  </span>
                  <DarkTooltip
                    title={
                      translates?.login?.passwordTooltipRequirements ||
                      'Password needs to be at least 6 characters and needs to include one lower case letter, one upper case letter, one number and one special character.'
                    }
                  >
                    <HelpIcon sx={{ color: colors.blue }} />
                  </DarkTooltip>
                </div>
                <TextField
                  className="customInput"
                  error={!!error}
                  fullWidth
                  value={value}
                  onChange={(e) => onChange(e.target.value.trim())}
                  helperText={
                    !!error
                      ? `${translates?.login?.passwordHelperTextError || error.message || 'Incorrect password'}`
                      : ''
                  }
                  type={showPass_2 ? 'text' : 'password'}
                  placeholder="Re-type Password"
                  InputProps={{
                    endAdornment: (
                      <DarkTooltip
                        title={
                          showPass_2
                            ? `${translates?.login?.passwordTooltipTitleHide || 'Hide password'}`
                            : `${translates?.login?.passwordTooltipTitleShow || 'Show password'}`
                        }
                      >
                        <IconButton onClick={() => setShowPass_2(!showPass_2)}>
                          {showPass_2 ? <VisibilityOffIcon /> : <VisibilityIcon color="inherit" />}
                        </IconButton>
                      </DarkTooltip>
                    ),
                  }}
                  FormHelperTextProps={{ style: { fontSize: 16 } }}
                />
              </div>
            )}
          />
        </Grid>
      </Grid>

      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <button className={`customButton ${styles.buttonSavePassword}`} type="submit">
          {translates?.changePassword?.changePasswordBtnLabel || 'Save password'}
        </button>
      </Box>
    </form>
  );
};
