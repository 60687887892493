import Baha from './baha.png';
import Bell from './bell.png';
import Crescent from './crescent.png';
import Cross from './cross.png';
import David from './david.png';
import Dharma from './dharma.png';
import Heart from './heart.png';
import IconCandle from './IconCandle.png';
import IconEagleFeather from './iconEagleFeather.png';
import IconLitOilLamp from './iconLitOilLamp.png';
import IconMetis from './iconMetis.png';
import IconMetis2 from './IconMetis2.png';
import IconPoppy from './iconPoppy.png';
import IconPraying from './iconPraying.png';
import IconSmudgeBowl from './iconSmudgeBowl.png';
import IconThankYou from './iconThankYou.png';
import Jainism from './jainism.png';
import Khanda from './khanda.png';
import Om from './om.png';
import Rose from './rose.png';
import Tulip from './tulip.png';

interface IconObject {
  icon: string | null;
  title: string;
}

interface Icons {
  [key:string]:IconObject
};

export default {
  baha: { icon: Baha, title: 'Baha' },
  bell: { icon: Bell, title: 'Bell' },
  crescent: { icon: Crescent, title: 'Crescent' },
  cross: { icon: Cross, title: 'Cross' },
  david: { icon: David, title: 'David' },
  dharma: { icon: Dharma, title: 'Dharma' },
  heart: { icon: Heart, title: 'Heart' },
  iconCandle: { icon: IconCandle, title: 'Icon Candle' },
  iconEagleFeather: { icon: IconEagleFeather, title: 'Icon Eagle Feather' },
  iconLitOilLamp: { icon: IconLitOilLamp, title: 'Icon Lit Oil Lamp' },
  iconMetis: { icon: IconMetis, title: 'Icon Metis' },
  iconMetis2: { icon: IconMetis2, title: 'Icon Metis 2' },
  iconPoppy: { icon: IconPoppy, title: 'Icon Poppy' },
  iconPraying: { icon: IconPraying, title: 'Icon Praying' },
  iconSmudgeBowl: { icon: IconSmudgeBowl, title: 'Icon Smudge Bowl' },
  iconThankYou: { icon: IconThankYou, title: 'Icon Thank You' },
  jainism: { icon: Jainism, title: 'Jainism' },
  khanda: { icon: Khanda, title: 'Khanda' },
  om: { icon: Om, title: 'Om' },
  rose: { icon: Rose, title: 'Rose' },
  tulip: { icon: Tulip, title: 'Tulip' }
} as Icons
