import React from 'react';
import {
  Box, FormControlLabel,
  Stack, Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useMediaQuery,
} from '@mui/material';

import { TProfile, TRemoveAccess, TShareQuilt, TUserQuilt } from 'api/http-client/profileTypes';

import {
  AccessPermissionsDisplayEnum,
  AccessPermissionsTextEnum,
} from 'components/CreateMemorialQuiltComponents/CreateMemorialQuiltStepTwo/const';
import { EditButton, ViewButton } from './TableButtons';
import { QuiltsTableRow } from './QuiltsTableRow';
import {
  myTableHeaderFields,
  myTableHeaderFieldsMobile,
  myTableHeaderFieldsTablet,
  sizeColumnInviteMemorials,
  sizeColumnInviteMemorialsMobile,
  sizeColumnMyMemorials,
  sizeColumnMyMemorialsMobile,
  sizeColumnMyMemorialsTablet,
  tableHeaderFields,
  tableHeaderFieldsMobile,
} from './const';

import styles from './styles.module.scss';
import {useRedirectAndScroll} from "../../hooks";
import {Link} from "react-router-dom";

interface MyMemorialsProps {
  quiltsList: TUserQuilt[] | null;
  user: TProfile;
  editableList: TUserQuilt[] | null;
  publicUnpublic: (quilt: TUserQuilt) => void;
  publishUnpublish: (quilt: TUserQuilt) => void;
  shareQuilt: (quilt: TShareQuilt) => void;
  removeAccess: (data: TRemoveAccess) => void;
  deleteUserQuilt: (id: string) => void;
}

export const MyMemorials: React.FC<MyMemorialsProps> = ({
  quiltsList,
  user,
  editableList,
  publicUnpublic,
  publishUnpublish,
  shareQuilt,
  removeAccess,
  deleteUserQuilt,
}) => {
  const { onlyRedirect } = useRedirectAndScroll({})
  const matches_1400 = useMediaQuery('(min-width: 1400px)');
  const matches_900 = useMediaQuery('(min-width: 900px)');

  const editButtonClick = (value: TUserQuilt) => {
    switch (value.access) {
      case AccessPermissionsTextEnum.EDIT_IMAGES:
        onlyRedirect('/add-images/' + value.id);
        break;
      case AccessPermissionsTextEnum.EDIT_IMAGES_MEMORIAL:
        onlyRedirect('/update/' + value.id + '?step=3');
        break;
    }
  };

  return (
    <section>
      <Stack direction="column" spacing={2} mt={2} mb={6}>
        <span className={styles.tab_title}>My Memorials</span>

        <Stack spacing={2}>
          <span className="defaultText colorGrey">
            Here you can manage the memorials that you have created. You can change how your
            memorial is displayed (private/public), edit your memorial or deleted it. You can also
            manage the permissions of other invited users.
          </span>
          <span className="defaultText colorGrey">
            View your memorial to share it on social media, with others by email and to manage
            tributes and condolences.
          </span>
        </Stack>
      </Stack>
      {quiltsList && quiltsList.length > 0 ? (
        <TableContainer sx={{ maxWidth: '100%', overflowY: 'hidden' }}>
          <Table
            size="small"
            sx={{ overflow: 'auto', tableLayout: 'fixed', minWidth: '550px' }}
            className="customTable"
          >
            <TableHead>
              <TableRow>
                {matches_1400
                  ? myTableHeaderFields.map((e, idx) => (
                      <TableCell
                        key={idx}
                        align="left"
                        sx={{ width: sizeColumnMyMemorials[idx], border: 'none' }}
                      >
                        <span className="defaultTitle1">{e}</span>
                      </TableCell>
                    ))
                  : matches_900
                    ? myTableHeaderFieldsTablet.map((e, idx) => (
                        <TableCell
                          key={idx}
                          align="left"
                          sx={{ width: sizeColumnMyMemorialsTablet[idx], border: 'none' }}
                        >
                          <span className="defaultTitle1">{e}</span>
                        </TableCell>
                      ))
                    : myTableHeaderFieldsMobile.map((e, idx) => (
                        <TableCell
                          key={idx}
                          align="left"
                          sx={{ width: sizeColumnMyMemorialsMobile[idx], border: 'none' }}
                        >
                          <span className="defaultTitle1">{e}</span>
                        </TableCell>
                      ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {quiltsList.map((quilt, index) => (
                <QuiltsTableRow
                  key={quilt.id}
                  quilt={quilt}
                  userEmail={user.email}
                  publicUnpublic={publicUnpublic}
                  publishUnpublish={publishUnpublish}
                  shareQuilt={shareQuilt}
                  removeAccess={removeAccess}
                  deleteQuilt={deleteUserQuilt}
                  rowStyle={styles.tableRowBorderBottom}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Box className="defaultText">No Memorials created yet. <Link className="link" to="/create?step=1">Create your first memorial</Link></Box>
      )}

      <Stack direction="column" spacing={2} mt={10} mb={6}>
        <span className="defaultTitle1">Other Memorials I Can Edit</span>

        <span className="defaultText colorGrey">
          If you have been invited to add photos or edit another memorial, you can access it here.
          Note that if you have been given permissions to "add photos only" you will only be able to
          add photos to the gallery, and will not be able to edit other parts of the memorial.
        </span>
      </Stack>

      {editableList && editableList.length > 0 ? (
        <TableContainer sx={{ maxWidth: { xs: '100%', md: '100%' }, marginBottom: 4 }}>
          <Table size="small" sx={{ overflow: 'auto', tableLayout: 'fixed', minWidth: '550px' }}>
            <TableHead>
              <TableRow>
                {matches_900
                  ? tableHeaderFields.map((e, idx) => (
                      <TableCell
                        key={idx}
                        align="left"
                        sx={{ width: sizeColumnInviteMemorials[idx] || '', border: 'none' }}
                      >
                        <span className="defaultTitle1">{e}</span>
                      </TableCell>
                    ))
                  : tableHeaderFieldsMobile.map((e, idx) => (
                      <TableCell
                        key={idx}
                        align="left"
                        sx={{
                          width: sizeColumnInviteMemorialsMobile[idx] || '',
                          border: 'none',
                        }}
                      >
                        <span className="defaultTitle1">{e}</span>
                      </TableCell>
                    ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {editableList.map((e) => (
                <TableRow key={e.id} className={styles.tableRowBorderBottom}>
                  <TableCell align="left" width={100} sx={{
                    overflow: "hidden"
                  }}>
                    <Box sx={{ width: 80, height: 80 }}>
                      <img
                        className={styles.imgTable}
                        src={e.finalImage ? e.finalImage.full : 'img/general/bg-2.jpg'}
                        alt={e.name}
                      />
                    </Box>
                    {!matches_900 && (
                      <Box className={styles.text}>
                        <span>{`${e?.firstName || ''} ${e?.lastName || ''}`} </span>
                      </Box>
                    )}
                  </TableCell>

                  {matches_900 && (
                    <TableCell align="left" className={styles.text}>
                      <span>{`${e?.firstName || ''} ${e?.lastName || ''}`} </span>
                    </TableCell>
                  )}

                  <TableCell align="left" sx={{
                    opacity: 0.7
                  }}>
                    <span className={styles.text} style={{ ...(e.isPublished ? { opacity: 0.5 } : {}) }}>
                      {' '}
                      Draft{' '}
                    </span>
                      <FormControlLabel
                        control={
                          <Switch disabled={true} checked={e.isPublished} onChange={() => publishUnpublish(e)} />
                        }
                        label={''}
                        className={styles.text}
                        sx={{
                          margin: 0,
                          marginTop: -1,
                        }}
                      />
                      <span className={styles.text} style={{ ...(!e.isPublished ? { opacity: 0.5 } : {}) }}>
                        {' '}
                        Published{' '}
                    </span>
                  </TableCell>

                  <TableCell style={{ padding: 5 }} align="left">
                    <ViewButton
                      viewButtonClick={() => {
                        onlyRedirect('/quilts/' + e.id + '/memorial');
                      }}
                      isEditableList={true}
                    />
                  </TableCell>

                  <TableCell style={{ padding: 5 }} align="left">
                    <EditButton editButtonClick={() => editButtonClick(e)} isEditableList={true} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Box className="defaultText" pb={{ xs: 4, md: 10 }}>
          No other memorials
        </Box>
      )}
    </section>
  );
};
