import React from 'react';
import { Stack } from '@mui/material';

import { useTranslates } from 'providers/translates';
import { PasswordForm } from 'components/PasswordForm';
import { TProfile } from 'api/http-client/profileTypes';

import { UserDetails } from './UserDetails';

import styles from './styles.module.scss';

interface UserSettingsProps {
  user: TProfile | null;
  saveUserData: (data: TProfile) => void;
  changePassword: (password: string) => void;
}

export const UserSettings: React.FC<UserSettingsProps> = ({
  user,
  saveUserData,
  changePassword,
}) => {
  const { translates } = useTranslates();

  return (
    <section style={{ margin: '16px 0 50px' }}>
      <h2 className={styles.tab_title}>
        {translates?.userSettings?.userSettingsTitle || 'User Settings'}
      </h2>
      <Stack mt={{ xs: 4, md: 8 }} spacing={5}>
        <span className="defaultTitle">Change Profile Information</span>
        {user && <UserDetails user={user} saveUserData={saveUserData} />}
      </Stack>

      <Stack mt={{ xs: 4, md: 8 }} spacing={5}>
        <span className="defaultTitle">
          {translates?.userSettings?.changePasswordTitle || 'Change password'}
        </span>
        <PasswordForm changePassword={changePassword} />
      </Stack>
    </section>
  );
};
