import React from 'react';
import { useTranslates } from 'providers/translates';

import styles from './footer.module.scss';
import { Link } from 'react-router-dom';

const Footer: React.FC = () => {
  const { translates } = useTranslates();

  return (
    <footer className={styles.footer}>
      <div className="center">
        <div className={styles.footer_block}>
          <a
            href="https://www.virtualhospice.ca/en_US/Main+Site+Navigation/Home.aspx"
            target="__blank"
            className="logo"
          >
            <img src="/img/general/logo-white-footer.png" alt="VirtualHospice" />
          </a>
          <div className={styles.footer_info}>
            <ul className={`${styles.footer_list} ${styles.isDesktop}`}>
              <li>
                {/*href="mailto:info@virtualhospice.ca?subject=I have questions about the Canadian Healing Quilt."*/}
                <a href="mailto:info@virtualhospice.ca?subject=I%20have%20a%20question%20about%20the%20Canadian%20Healing%20Quilt&body=%0A%0A%0ANote%3A%20If%20you%20are%20a%20current%20Canadian%20Healing%20Quilt%20user%20and%20are%20looking%20for%20assistance%2C%20please%20provide%20us%20with%20the%20following%20information%20so%20we%20can%20help%20you%20as%20easily%20as%20possible%3A%0A%0AName%20associated%20with%20your%20account%3A%0AEmail%20address%20associated%20with%20your%20account%3A%0AName%20of%20the%20quilt%20you%20need%20help%20with%3A%0AIssue%20or%20concern%3A">
                  {translates?.footer?.contactUs || 'Contact us'}
                </a>
              </li>
              <li className={styles.footer_verticalLine}></li>
              <li>
                <Link to={'/howtouse/?visible=notice'}
                  target="__blank"
                  rel="noreferrer">
                  {translates?.footer?.privacyNotice || 'Privacy Notice'}
                </Link>
              </li>
              <li className={styles.footer_verticalLine}></li>
              <li>
                <a
                  href="https://www.virtualhospice.ca/en_US/Main+Site+Navigation/Right+Footer+Navigation/Privacy+Policy.aspx"
                  target="_blank"
                  rel="noreferrer"
                >
                  {translates?.footer?.privacyPolicy || 'Privacy Policy'}
                </a>
              </li>
            </ul>
            <ul className={`${styles.footer_list} ${styles.isMobile}`}>
              <li>
                <a href="mailto:info@virtualhospice.ca?subject=I have questions about the MemorialQuilt">
                  {translates?.footer?.contactUs || 'Contact us'}
                </a>
              </li>
            </ul>
            <ul className={`${styles.footer_list} ${styles.isMobile}`}>
              <li>
                <Link to={'/howtouse/?visible=notice'}
                  target="__blank"
                  rel="noreferrer">
                  {translates?.footer?.privacyNotice || 'Privacy Notice'}
                </Link>
              </li>
              <li className={styles.footer_verticalLine}></li>
              <li>
                <a
                  href="https://www.virtualhospice.ca/en_US/Main+Site+Navigation/Right+Footer+Navigation/Privacy+Policy.aspx"
                  target="_blank"
                  rel="noreferrer"
                >
                  {translates?.footer?.privacyPolicy || 'Privacy Policy'}
                </a>
              </li>
            </ul>

            <span className={styles.footer_copyright}>
              {translates?.footer?.copyright ||
                'Copyright 2016-2024 Canadian Virtual Hospice. All Rights Reserved.'}
            </span>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
