import React, {FC} from "react";
import styles from "./styles.module.scss";
import {TPositionBlock, TSideBlock} from "./model";

interface ClickAddBlockProps {
	addNewBlockText?: (
		position?: TPositionBlock,
		side?: TSideBlock,
		blockId?: string,
	) => void;
	addNewBlockImage?: (position?: TPositionBlock, side?: TSideBlock, blockId?: string) => void
	isNoClick?: boolean;
}

export const ClickAddBlock: FC<ClickAddBlockProps> = ({ addNewBlockText, isNoClick, addNewBlockImage }) => {
	return (
		<div className={styles.editorBlock__add}>
			<div className={styles.editorBlock__addIcon} onClick={() => addNewBlockText && addNewBlockText()} style={{
				cursor: isNoClick ? 'default' : 'pointer'
			}}>
				<img src="/img/general/text-size-icon.png" alt="text-size" />
				<span>Click here to add text</span>
			</div>

			<span className={styles.editorBlock__or}>OR</span>

			<div className={styles.editorBlock__addIcon} style={{
				cursor: isNoClick ? 'default' : 'pointer'
			}} onClick={() => addNewBlockImage && addNewBlockImage()}>
				<img src="/img/general/image-icon.png" alt="iamge-icon" />
				<span>Click here to add images</span>
			</div>
		</div>
	)
}
