import styles from '../styles.module.scss'
import Candle from '../icons/candle-icon.svg'
import { Collapse, Divider } from '@mui/material';
import { useState } from 'react';
import { TCandle } from 'api/http-client/types';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

interface ICandlesProps {
  candles: TCandle[];
  editable?: boolean;
  deleteCandle: (candleId: string) => void
}

export const ShowCandles = ({ candles, editable, deleteCandle }: ICandlesProps) => {

  const [showAllCandles, setShowAllCandles] = useState(false);

  return <div className={styles.candles}>
    <h2 className={styles.title}>Candles</h2>
    {candles.length > 0 ?
      <>
        {candles.slice(0, 9).map((candle) => {
          return (
            <div key={candle.id} className={styles.candle__Item}>
              <img src={Candle} alt={'Candle'} />
              <p>{candle.name}</p>
              {editable &&
                <div className={styles.delete} onClick={() => { deleteCandle(candle.id.toString()) }}>
                  <DeleteOutlineIcon />
                </div>}
            </div>
          );
        })
        }
        <Collapse in={showAllCandles} timeout={700}>
          {candles.slice(9).map((candle) => {
            return (
              <div key={candle.id} className={styles.candle__Item}>
                <img src={Candle} alt={'Candle'} />
                <p>{candle.name}</p>
                {editable &&
                  <div className={styles.delete} onClick={() => { deleteCandle(candle.id.toString()) }}>
                    <DeleteOutlineIcon />
                  </div>}
              </div>
            );
          })
          }
        </Collapse>
        <Divider className={styles.devider} />
      </> : <p>Tributes and Condolences have not been created yet</p>
    }

    {candles.length > 9 && <div className={styles.loadMore}>
      <button className={`customButton ${styles.btn}`} onClick={() => setShowAllCandles(show => !show)}>
        {showAllCandles ? 'Show Less' : 'Load More'}
        {showAllCandles ? <ArrowDropUpIcon className={styles.actionArow} fontSize={'large'} sx={{ color: '#1D486F' }} /> : <ArrowDropDownIcon className={styles.actionArow} fontSize={'large'} sx={{ color: '#1D486F' }} />}
      </button>
    </div>}
  </div>
}




