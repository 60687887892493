import React, {FC, useEffect, useState} from "react";
import { useParams, useSearchParams } from "react-router-dom";

import styles from "./styles.module.scss";
import {useRedirectAndScroll} from "../../../hooks";
import {downloadPDF} from "../../../utilities";

interface CreateMemorialQuiltHeaderProps {
    title?: string;
    isActiveStep: number;
    steps: {
      index: number,
      title: string
    }[];
    isShowInspiration?: boolean;
}

export const CreateMemorialQuiltHeader: FC<CreateMemorialQuiltHeaderProps> = ({title = "Create Your Memorial", isActiveStep, steps, isShowInspiration = true}) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const { id } = useParams();
  const { onlyRedirect } = useRedirectAndScroll({})

  const [canChangeStep, setCanChangeStep] = useState(false)

  useEffect(() => {
    let step = searchParams.get('step');

    if (!step) {
      setSearchParams({
        step: '1'
      })
    }

    setCanChangeStep(!!id)
  }, [id, isActiveStep, searchParams, setSearchParams])

  const getIsActive = (index: number) : string => isActiveStep >= index ? styles.active : ''

  const changeStep = (step: number) => {
    // canChangeStep && setSearchParams({
    //   step: `${step}`
    // })

    canChangeStep && onlyRedirect(`/update/${id}?step=${step}`);
  }

  return (
      <div className={`center ${styles.header}`} id="createQuiltHeader">
        <span className={styles.title}>{title}</span>

        <div className={styles.header__items}>
          <div className={styles.steps}>
            {steps.map(step => {
              return (
                <div className={styles.step} key={step.index} onClick={() => changeStep(step.index)}>
                  <div className={styles.stepCircle}>
                    <div className={`${styles.stepCircle__inside} ${getIsActive(step.index)}`}>{step.index}</div>
                  </div>

                  <span className={styles.stepCircle__text}>{step.title}</span>
                </div>
              )
            })}
          </div>

          {isShowInspiration && (
            <div className={styles.header__inspiration}>
              <span className={styles.header__inspirationText}>Need inspiration?</span>

              <button className={`button ${styles.header__inspirationButton}`}>
                <a onClick={() => downloadPDF("./SampleHealingQuilt.pdf", "Sample Healing Quilt")}>
                  See Some examples
                </a>
              </button>
            </div>
          )}
        </div>
      </div>
  );
};
