const accessImageDialog = {
  title: 'Access Image',
  text: 'We need your permission to access your file system to proceed',
};

const successBuilderDialog = {
  title: 'Success!',
  text: 'You Have Completed Your Memorial!',
};

const selectingWordStyle = "'color: #333333; text-transform: uppercase; font-weight: 700'";

const errorBuilderPublishDialog = {
  title: 'Error!',
  text: `
		<div style="margin-bottom: 6px">
		 Your memorial is still in draft form!
		</div>
		Select your display settings and click <span style=${selectingWordStyle}>PUBLISH</span> to complete your memorial. If you are not ready to <span style=${selectingWordStyle}>PUBLISH</span>, you can view your memorial by clicking <span style=${selectingWordStyle}>PREVIEW</span>.
	`,
};

const errorBuilderPrivateDialogTitle = "'margin-bottom: 6px; font-weight: 700; color: #333333'";

const errorBuilderPrivateDialog = {
  title: 'Warning!',
  text: `
			<div style=${errorBuilderPrivateDialogTitle}>
				Your memorial is currently set to private.
			</div>
			Since your memorial is currently set to private, your quilt square will not be visible on the home page. You can change your memorial to PUBLIC in display settings.
			<div style="margin-top: 6px">You can also view your quilt by using the search option on the homepage.</div>
		`,
};

export {
  accessImageDialog,
  successBuilderDialog,
  errorBuilderPublishDialog,
  errorBuilderPrivateDialog,
};
