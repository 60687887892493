import React from 'react';

import styles from './resources.module.scss';

const Resources: React.FC = () => {
  return (
    <section className="center">
      <h1 className="bigTitle">Find Support</h1>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
        labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
        laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
        voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
        cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
      </p>
      <div className={styles.line} />
      <h2 className="bigTitle">Crisis Resources</h2>
      <h4 className="mt-50 defaultTitle1">Wellness Together Canada</h4>
      <p className="my-20">To connect with a mental health professional one-on-one:</p>
      <ul>
        <li className={styles.list_item}>
          <span className={styles.list_dot}></span>call 1-888-668-6810 or text WELLNESS to 686868
          for youth
        </li>
        <li className={styles.list_item}>
          <span className={styles.list_dot}></span>call 1-866-585-0445 or text WELLNESS to 741741
          for adults
        </li>
      </ul>
      <p className="my-20">
        You can also visit Wellness Together Canada to access different levels of support,
        including:
      </p>
      <ul>
        <li className={styles.list_item}>
          <span className={styles.list_dot}></span>one-on-one counselling
        </li>
        <li className={styles.list_item}>
          <span className={styles.list_dot}></span>credible articles and information
        </li>
        <li className={styles.list_item}>
          <span className={styles.list_dot}></span>self-guided courses and programs
        </li>
        <li className={styles.list_item}>
          <span className={styles.list_dot}></span>peer support and coaching
        </li>
      </ul>
      <h4 className="mt-50 defaultTitle1">Suicide Crisis</h4>
      <ul>
        <li className={styles.list_item}>
          <span className={styles.list_dot}></span>Support for immediate crisis or suicide-related
          concerns.
        </li>
        <li className={styles.list_item}>
          <span className={styles.list_dot}></span>1-833-456-4566 toll free (In QC: 1-866-277-3553),
          24/7 or visit &nbsp;
          <a className={styles.http_link} href="https://988.ca" target="_blank" rel="noreferrer">
            talksuicide.ca
          </a>
          .
        </li>
        <li className={styles.list_item}>
          <span className={styles.list_dot}></span>Text to 45645 (4 p.m. – Midnight ET). Text
          messaging rates apply. French text support is currently unavailable.
        </li>
      </ul>
      <div className={styles.line} />
      <h2 className="bigTitle">For First Nations, Inuit, and Métis Peoples</h2>
      <h4 className="mt-50 defaultTitle1">Hope for Wellness Help Line</h4>
      <ul>
        <li className={styles.list_item}>
          <span className={styles.list_dot}></span>Call 1-855-242-3310 (toll-free) or connect to the
          online &nbsp;
          <a
            className={styles.http_link}
            href="https://www.hopeforwellness.ca"
            target="_blank"
            rel="noreferrer"
          >
            Hope for Wellness chat
          </a>
          .
        </li>
        <li className={styles.list_item}>
          <span className={styles.list_dot}></span>Available 24 hours a day, 7 days a week to First
          Nations, Inuit, and Métis Peoples seeking emotional support, crisis intervention, or
          referrals to community-based services.
        </li>
        <li className={styles.list_item}>
          <span className={styles.list_dot}></span>Support is available in English and French and,
          by request, in Cree, Ojibway, and Inuktitut.
        </li>
      </ul>
      <h4 className="mt-50 defaultTitle1">The National Indian Residential School Crisis Line</h4>
      <p className="my-20">
        Crisis support is available to former Indian Residential School students and their families
        24 hours a day, 7 days a week at 1-866-925-4419 (toll-free).
      </p>
      <h4 className="mt-50 defaultTitle1">
        Missing and Murdered Indigenous Women and Girls Crisis Line
      </h4>
      <p className="my-20">
        Crisis support is available to individuals impacted by the issue of missing and murdered
        Indigenous women, girls, and 2SLGBTQQIA+ people 24 hours a day, 7 days a week at
        1-844-413-6649 (toll-free).
      </p>
      <div className={styles.line} />
      <h2 className="bigTitle">Grief Resources</h2>
      <p className="my-20">
        You are not alone. Canadian Virtual Hospice provides online Discussion Forums where you can
        connect with others who are living with loss and grieving. You can share, you can vent, and
        you will find understanding and support. Our community has been described as “a hug by a
        warm blanket.”
      </p>
      <p className="my-20">
        You can also connect with a health professional by selecting our Ask a Professional feature
        to pose a confidential question. A member of the Canadian Virtual Hospice interdisciplinary
        clinical team will respond within three business days (not including Canadian statutory
        holidays).
      </p>
      <div className={styles.line} />
      <div className={styles.image_block}>
        <img className={styles.image} src="/img/general/person-3.jpg" alt="Resources" />
        <div className={styles.text_block}>
          <h4 className="mt-0 defaultTitle">MyGrief.ca</h4>
          <p className="my-20">
            Confidential and free, MyGrief.ca helps you to understand and move through your grief.
          </p>
          <ul>
            <li className={styles.list_item}>
              <span className={styles.list_dot}></span>In your own place, at your own pace
            </li>
            <li className={styles.list_item}>
              <span className={styles.list_dot}></span>Developed by people who have "been there" and
              grief specialists
            </li>
            <li className={styles.list_item}>
              <span className={styles.list_dot}></span>Features "real life" stories and experiences
            </li>
          </ul>
        </div>
      </div>
      <div className={styles.line} />
      <div className={styles.image_block}>
        <div className={styles.text_block}>
          <h4 className="mt-0 defaultTitle">KidsGrief.ca</h4>
          <p className="my-20">
            KidsGrief.ca is a free online resource that helps parents support their children when
            someone in their life is dying or has died. It equips parents with the words and
            confidence needed to help children grieve life’s losses in healthy ways.
          </p>
        </div>
        <img className={styles.image} src="/img/general/person-4.jpg" alt="Resources" />
      </div>
    </section>
  );
};

export default Resources;
